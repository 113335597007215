<template>
  <div
    data-id="page"
    :class="[
      $style.container,
      {
        [$style.container_sidebar]: this.sidebar,
        [$style.container_sheet]: this.sheet,
      },
    ]"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "LayoutPage",
  props: {
    /**
     * Добавляет колонку для сайбара на страницу(участник)
     */
    sidebar: {
      type: Boolean,
      default: false,
    },
    /**
     * Позволяет отображать внизу контент ввиде всплывающих окон
     */
    sheet: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" module>
.container {
  position: relative;
  display: grid;
  grid-template-areas: "content";
  grid-template-rows: 1fr;
  height: 100%;
  overflow: hidden;

  &_sidebar {
    grid-template-areas: "content sidebar";
    grid-template-columns: 1fr auto;
  }

  &_sheet {
    grid-template-areas:
      "content"
      "sheet";
    grid-template-rows: 1fr auto;
  }
}
</style>
