<template>
  <div
    class="broadcast-participants"
    :class="{
      'broadcast-participants--inline': !fullMode,
      'broadcast-participants--full-mode': fullMode,
    }"
  >
    <div v-if="fullMode" class="broadcast-participants__peak">
      <ws-button icon color="default" @click="$emit('exit-full-mode')">
        <ws-icon xl light>times</ws-icon>
      </ws-button>
    </div>

    <div v-if="participantsOnAir.length" class="broadcast-participants__group">
      <h3 v-show="fullMode" class="broadcast-participants__group-title">
        {{ $t("broadcast.participantsOnAir") }}
      </h3>
      <speaker-broadcast-participant-item
        v-for="participant in participantsOnAir"
        :key="participant.user_id"
        :participant="participant"
        :owner="participant.user_id === speaker.id"
        :current-control-user-id="currentControlUserId"
        @toggle-audio="$emit('off-audio', participant.user_id)"
        @toggle-video="$emit('off-video', participant.user_id)"
        @toggle-control="toggleHandler($event, participant.user_id)"
        @disconnect="$emit('disconnect', participant.user_id)"
      />
    </div>

    <ws-button v-if="!fullMode" lg color="primary" outlined @click="$emit('show-all')">
      {{ $t("broadcast.allParticipants") }}
    </ws-button>

    <div v-if="fullMode && participantsRaiseHand.length" class="broadcast-participants__group">
      <h3 class="broadcast-participants__group-title">
        {{ $t("broadcast.participantsHandUp") }}
      </h3>
      <speaker-broadcast-participant-item
        v-for="participant in participantsRaiseHand"
        :key="participant.user_id"
        :participant="participant"
        @accept="$emit('accept', participant.user_id)"
      />
    </div>

    <div v-if="fullMode && participantsViewers.length" class="broadcast-participants__group">
      <h3 class="broadcast-participants__group-title">
        {{ $t("broadcast.participantsViewers") }}
      </h3>
      <speaker-broadcast-participant-item
        v-for="participant in participantsViewers"
        :key="participant.user_id"
        :participant="participant"
        @disconnect="$emit('disconnect', participant.user_id)"
      />
    </div>

    <p v-if="fullMode && participantsOthersCountText" class="broadcast-participants__guest-counter">
      {{ participantsOthersCountText }}
    </p>
  </div>
</template>

<script>
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";
import SpeakerBroadcastParticipantItem from "./SpeakerBroadcastParticipantsItem";

export default {
  name: "SpeakerBroadcastParticipants",
  components: {
    WsButton,
    WsIcon,
    SpeakerBroadcastParticipantItem,
  },
  props: {
    /**
     * Show a full list of participants in modal mode with own scroll,
     * otherwise only speakers (main speaker and participants on the air).
     */
    fullMode: {
      type: Boolean,
      default: false,
    },
    /**
     * Participants array
     *
     * @typedef {object[]} participant                   - participant item
     * @param   {string}  participants[].user_id    - participant id
     * @param   {string}  participants[].name  - participant name
     * @param   {string}  participants[].avatar - participant profile photo's url
     * @param   {Array}   participants[].permissions - participant status in current broadcast
     */
    participants: {
      type: Array,
      default: () => [],
    },
    /**
     * Speaker data in broadcast context
     *
     * @typedef {object} speaker
     * @param {string} speaker.id    - speaker user id
     * @param {string} speaker.name  - speaker name for participants list
     * @param {string} speaker.photo - speaker profile photo's url
     * @property
     */
    speaker: {
      type: Object,
      required: true,
    },
    /**
     * @value {number}
     */
    viewersCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    participantsOnAir() {
      return this.participants.filter(p => p.onAir);
    },
    participantsRaiseHand() {
      return this.participants.filter(p => p.raiseHand && !p.onAir);
    },
    participantsViewers() {
      return this.participants.filter(p => !p.raiseHand && !p.onAir);
    },
    notOnAirParticipantsCount() {
      return this.participants.filter(p => !p.onAir).length;
    },
    participantsOthersCountText() {
      const count = this.viewersCount - this.notOnAirParticipantsCount;

      if (count < 1) {
        return "";
      }
      return this.$t("broadcast.andMoreGuests", [this.$tc("plurals.guest", count)]);
    },
    currentControlUserId() {
      return this.participantsOnAir.find(p => p.permissions?.presentationControl)?.user_id || 0;
    },
  },
  methods: {
    toggleHandler(event, userId) {
      if (this.currentControlUserId && this.currentControlUserId !== userId) {
        this.$emit("toggle-control", false, this.currentControlUserId);
      }

      this.$emit("toggle-control", event, userId);
    },
  },
};
</script>

<style scoped lang="less">
@import "~@/styles/_vars";
@import "~@/styles/_mixin";

.broadcast-participants {
  &--inline {
    display: grid;
    grid-template-rows: 1fr max-content;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    width: 100%;
    max-height: 100%;
    padding: 0 15px;
    overflow: hidden;
  }

  &--full-mode {
    height: 100%;
    overflow: auto;
    background-color: @white;
  }

  &__peak {
    position: sticky;
    top: 0;
    z-index: 100;
    padding: 10px 10px 0;
    text-align: right;
    background: @white;
    background: linear-gradient(to bottom, rgba(@white, 1) 80%, rgba(@white, 0) 100%);
  }

  &__group {
    padding: 0 0 10px;
  }

  &--inline &__group {
    .reset-scroll();

    overflow: auto;
  }

  &--full-mode &__group {
    padding: 30px 20px 20px;

    &:first-of-type {
      padding-top: 0;
    }

    &:not(:last-of-type) {
      border-bottom: solid 1px @modal-block-border;
    }
  }

  &__group-title {
    margin: 0 0 10px;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: @text-dark;
  }

  &__guest-counter {
    height: 50px;
    margin: 10px 20px 20px;
    line-height: 45px;
    text-align: center;
    color: @text-gray;
    border: 1px solid @modal-block-border;
    border-radius: 5px;
  }
}
</style>
