<template>
  <layout-navigation>
    <div
      slot="before"
      :class="[
        $style.beforeNav,
        {
          [$style.padded]: !isPresentationRoute,
        },
      ]"
      class="flex flex-center"
    >
      <img v-show="!isPresentationRoute" src="@/assets/images/ws-lite.svg" alt="WhenSpeak" />
      <navigation-link
        v-if="!this.$isMobile"
        v-show="isPresentationRoute"
        :item="{
          route: 'speaker-presentations',
          icon: 'arrow-left',
          text: $t('navigation.allPresentations'),
        }"
      />
    </div>
    <navigation
      slot="navigation"
      class="uk-flex uk-flex-center"
      :class="$style.navigation"
      :items="navigationItems"
    />
    <ws-button
      slot="after"
      data-testid="burgerMenuBtn"
      lg
      icon
      :class="$style.menuBtn"
      color="default"
      @click="$emit('menu-show')"
    >
      <ws-icon xxl light color="white">ellipsis-v</ws-icon>
    </ws-button>
  </layout-navigation>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { AVAILABLE_ROOM_MODULES } from "@/store/modules/common/getter-types";
import { IS_BROADCAST_ENABLED, IS_CONFERENCE_TYPE } from "@/store/modules/broadcast/getter-types";
import { room, systemTest, broadcast, speakerPolls } from "@/store/modules/store.namespaces";
import {
  START_ABOUT_WS_QUIZ,
  STOP_ABOUT_WS_QUIZ,
} from "@/store/modules/speaker/modules/polls/action-types";
import { ACTIVE_ABOUT_WS } from "@/store/modules/speaker/modules/getter-types";
import { SpeakerRouteName } from "@/constants/router/router-const";

import Navigation from "@/components/common/navigation/Navigation";
import NavigationLink from "@/components/common/navigation/NavigationLink";
import LayoutNavigation from "@/components/layouts/LayoutNavigation";
import WsIcon from "@/components/base/WsIcon";
import WsButton from "@/components/base/WsButton";

export default {
  name: "SpeakerHeader",
  components: { Navigation, NavigationLink, LayoutNavigation, WsButton, WsIcon },
  computed: {
    ...mapState(room, ["roomInfo", "roomNumber"]),
    ...mapGetters(room, {
      availableRoomModules: AVAILABLE_ROOM_MODULES,
    }),
    ...mapState(systemTest, ["testErrorsCounter"]),
    ...mapGetters(broadcast, {
      isConferenceType: IS_CONFERENCE_TYPE,
      isBroadcastEnabled: IS_BROADCAST_ENABLED,
    }),
    ...mapGetters(speakerPolls, {
      activeAboutWs: ACTIVE_ABOUT_WS,
    }),
    isRoomListRoute() {
      return this.$route.name === SpeakerRouteName.Rooms;
    },
    isPresentationRoute() {
      return !!this.$route.params.presId || /\/presentations\//i.test(this.$route.fullPath);
    },
    navigationItemsMap() {
      return {
        back:
          this.$isMobile && this.isPresentationRoute
            ? this.navigationItemFactory(
                SpeakerRouteName.Presentations,
                "arrow-left",
                this.$t("navigation.allPresentations"),
                0,
              )
            : null,
        presentations: this.navigationItemFactory(
          !this.isPresentationRoute ? SpeakerRouteName.Presentations : "presentation-control",
          "desktop",
          this.isPresentationRoute
            ? this.$t("navigation.presentation")
            : this.$t("navigation.presentations"),
          1,
        ),
        questions: this.navigationItemFactory(
          this.isPresentationRoute ? "question" : "questions-in-room",
          "question-circle",
          this.$t("navigation.questions"),
          2,
        ),
        polls: this.isPresentationRoute
          ? this.navigationItemFactory(
              "polls",
              "clipboard-list-check",
              this.$t("navigation.testsAndGames"),
              3,
            )
          : null,
        brainstorm: this.navigationItemFactory(
          this.isPresentationRoute ? "brainwave" : "sp-brainwave",
          "brain",
          this.$t("navigation.brainstorm"),
          4,
        ),
        calendar: this.navigationItemFactory(
          this.isPresentationRoute ? "agendas" : "order-paper",
          "calendar-alt",
          this.$t("navigation.schedule"),
          5,
        ),
        miro: this.isPresentationRoute
          ? this.navigationItemFactory("miro", "pencil-ruler", this.$t("navigation.miro"), 6)
          : null,
        aboutWs: this.isPresentationRoute
          ? this.navigationItemFactory(
              "",
              "info-circle",
              this.$t("navigation.endPresentation"),
              7,
              this.toggleWsQuiz,
              () => this.activeAboutWs,
            )
          : null,
      };
    },
    navigationItems() {
      if (this.isRoomListRoute) {
        return [];
      }
      return Object.entries(this.navigationItemsMap)
        .map(([key, value]) => {
          if (value && key in this.availableRoomModules && !this.availableRoomModules[key]) {
            return null;
          }
          return value;
        })
        .filter(Boolean)
        .sort((a, b) => a.order - b.order);
    },
  },
  methods: {
    ...mapActions(speakerPolls, {
      startAboutWsQuiz: START_ABOUT_WS_QUIZ,
      stopAboutWsQuiz: STOP_ABOUT_WS_QUIZ,
    }),
    async toggleWsQuiz() {
      if (!this.activeAboutWs) {
        await this.startAboutWsQuiz();
      } else {
        await this.stopAboutWsQuiz();
      }
    },
    navigationItemFactory(route, icon, text, order, callback = null, activeFunc = null) {
      return {
        route,
        icon,
        text,
        callback,
        order,
        activeFunc,
      };
    },
  },
};
</script>

<style lang="scss" module>
.beforeNav {
  grid-area: before;
}

.padded {
  padding-left: 20px;
}

.menuBtn {
  grid-area: after;
  height: 100%;
  border-radius: 0;
}

.navigation {
  grid-area: navigation;
}
</style>
