<template>
  <nav-side-bar
    :user-name="userName"
    :success-timer="successTimer"
    :expanded="burgerMenuOpened"
    :has-timer-module="!isRouteSpeakerRoomList"
    :has-notification-module="isNotificationVisible"
    :change-room-button-visible="isRoomChangeButtonVisible"
    :disabled-button-start-timer="disabledButtonStartTimer"
    @toggle-burger="toggleBurgerMenu"
    @set-push="setPush"
    @set-time="setTime"
    @reset-timer="resetTimer"
    @logout="logoutHandler"
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { auth, polls, presentation, room, speaker } from "@/store/modules/store.namespaces";
import { AVAILABLE_ROOM_MODULES } from "@/store/modules/common/getter-types";
import { GET_ROOMS, SEND_NOTIFICATION, SET_TIMER } from "@/store/modules/speaker/action-types";
import { SET_ROOMS, TOGGLE_BURGER_MENU } from "@/store/modules/speaker/mutation-types";
import { LOGOUT } from "@/store/modules/auth/action-types";
import { SpeakerRouteName } from "@/constants/router/router-const";
import { RESET_PRESENTATION_STATE } from "@/store/modules/presentation/mutation-types";
import { SET_POLLCHAIN_SCORE } from "@/store/modules/polls/mutation-types";
import { test } from "@/constants/polls/polls-module-types";

import NavSideBar from "@/components/speaker/navigation/SpeakerSidebar";

export default {
  name: "ContainerSpeakerSideBar",
  components: { NavSideBar },
  data() {
    return {
      successTimer: 0,
      disabledButtonStartTimer: true,
    };
  },
  computed: {
    ...mapState(room, ["roomNumber"]),
    ...mapState(auth, ["user"]),
    ...mapState(speaker, ["rooms", "burgerMenuOpened"]),
    ...mapGetters(room, {
      availableRoomModules: AVAILABLE_ROOM_MODULES,
    }),
    isNotificationVisible() {
      return this.availableRoomModules.notifications && !this.isRouteSpeakerRoomList;
    },
    isRouteSpeakerRoomList() {
      return this.$route.name === SpeakerRouteName.Rooms;
    },
    isRoomChangeButtonVisible() {
      return this.rooms.length > 1 && !this.isRouteSpeakerRoomList;
    },
    userName() {
      return this.user.fio || this.user.login;
    },
  },
  created() {
    if (!this.rooms.length) {
      this.getRooms();
    }
  },
  beforeDestroy() {
    if (this.burgerMenuOpened) {
      this.toggleBurgerMenu();
    }
  },
  methods: {
    ...mapActions(speaker, {
      getRooms: GET_ROOMS,
      setTimer: SET_TIMER,
      sendNotification: SEND_NOTIFICATION,
    }),
    ...mapMutations(speaker, {
      toggleBurgerMenu: TOGGLE_BURGER_MENU,
      resetRooms: SET_ROOMS,
    }),
    ...mapActions(auth, {
      logout: LOGOUT,
    }),
    ...mapMutations(presentation, {
      resetPresentationState: RESET_PRESENTATION_STATE,
    }),
    ...mapMutations(polls, {
      resetChainScore: SET_POLLCHAIN_SCORE,
    }),
    setPush(data) {
      data.user = this.user.fields;
      this.sendNotification({ ...data, room: this.roomNumber });
    },
    async setTime(timer, disabledButtonStartTimer) {
      this.disabledButtonStartTimer = disabledButtonStartTimer;
      const data = {
        room: this.roomNumber,
        timer,
      };
      this.successTimer = timer;
      await this.setTimer(data);
      this.successTimer = timer;
      setTimeout(() => {
        this.disabledButtonStartTimer = true;
        this.successTimer = 0;
      }, timer * 60000);
    },
    resetTimer(value) {
      this.disabledButtonStartTimer = value;
    },
    logoutHandler() {
      this.resetPresentationState();
      this.resetChainScore({
        type: test,
        payload: null,
      });
      this.resetRooms([]);
      this.logout();
    },
  },
};
</script>

<style scoped></style>
