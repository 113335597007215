<template>
  <div :class="$style.settingsSelect">
    <div class="text-sm" :class="$style.settingsSelect__label">
      <slot name="label"></slot>
    </div>
    <ws-select
      :disabled="disabled"
      :value="selected"
      :options="options"
      :selectable="selectable"
      :label="keys.label"
      :get-option-key="getOptionKey"
      fullscreen-group="broadcast"
      @input="onSelectedHandler"
    />
  </div>
</template>

<script>
import WsSelect from "@/components/base/WsSelect";

export default {
  name: "SpeakerBroadcastSettingsSelect",
  errorCaptured() {
    return false;
  },
  components: {
    WsSelect,
  },
  model: {
    prop: "selected",
    event: "input",
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Object,
      default: () => ({
        deviceId: "1",
        label: "Error",
        disabled: false,
      }),
    },
    disabled: Boolean,
    keys: {
      type: Object,
      default: () => ({
        key: "deviceId",
        label: "label",
        disabled: "disabled",
      }),
    },
  },
  methods: {
    onSelectedHandler(value) {
      this.$emit("input", value);
    },
    selectable(option) {
      return !option[this.keys.disabled];
    },
    getOptionKey(option) {
      return option[this.keys.key];
    },
  },
};
</script>

<style lang="scss" module>
.settingsSelect {
  width: 100%;
  cursor: pointer;

  &__label {
    margin-bottom: 5px;
    color: $gray-darken;
  }
}
</style>
