<template>
  <div class="broadcast-participant">
    <!--    todo: мы отказались от того что в списке должно отражаться кто говорит, или нет? -->
    <div
      class="broadcast-participant__photo"
      :class="{
        'broadcast-participant__photo--speaking': false,
      }"
      :style="{
        backgroundImage: participant.photo ? `url(${participant.photo})` : null,
      }"
    />

    <div class="broadcast-participant__name" :title="participant.name">
      {{ participant.name }}
    </div>

    <!--    <div v-if="owner" class="broadcast-participant__chip">-->
    <!--      {{ $t("broadcast.speaker") }}-->
    <!--    </div>-->

    <ws-button
      v-show="participant.onAir"
      icon
      class="broadcast-participant__icon-btn"
      @click="toggleControl"
    >
      <ws-icon light lg :color="participant.user_id === currentControlUserId ? 'black' : 'default'">
        {{ "user-chart" }}
      </ws-icon>
    </ws-button>

    <ws-button
      v-if="participant.raiseHand"
      class="broadcast-participant__button"
      rounded
      sm
      color="primary"
      @click="$emit('accept')"
    >
      {{ $t("common.accept") }}
    </ws-button>

    <template v-else-if="participant.onAir || participant.permissions.canPresent">
      <ws-button
        v-show="participant.onAir"
        icon
        class="broadcast-participant__icon-btn"
        :class="{
          'broadcast-participant__icon-btn--muted': !participant.permissions.audio,
        }"
        @click="$emit('toggle-audio')"
      >
        <ws-icon light lg color="black">
          {{ iconAudio }}
        </ws-icon>
      </ws-button>

      <ws-button
        v-show="participant.onAir"
        icon
        class="broadcast-participant__icon-btn"
        :class="{
          'broadcast-participant__icon-btn--muted': !participant.permissions.video,
        }"
        @click="$emit('toggle-video')"
      >
        <ws-icon light lg color="black">
          {{ iconVideo }}
        </ws-icon>
      </ws-button>

      <ws-button
        v-if="!owner"
        icon
        class="broadcast-participant__icon-btn broadcast-participant__icon-btn--muted"
        @click="$emit('disconnect')"
      >
        <ws-icon light>
          user-minus
        </ws-icon>
      </ws-button>
    </template>
  </div>
</template>

<script>
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";

/**
 * Компонент элемента списка участников который показывает актуальное состояние участника в рамках вещания ВКС
 * /**
 * @typedef {Object} Participant - participant item
 * @property {Number} user_id    - id
 * @property {String} name  - имя участника
 * @property {String} photo - ссылка на аватар участника
 * @property {Object} permissions - состояние прав участника
 * @property {Boolean} onAir - в эфире участник или нет
 * @property {Boolean} raiseHand - поднял руку участник или нет
 *
 * @vue-prop {participant} participant - модель участника для отображения
 * @vue-prop {Number} currentControlUserId - ид участника у которого есть права управлять презентацией
 * @vue-prop {Boolean} owner - показывает что данный участник в списке это главный спикер(не используется пока что)
 * @vue-computed {String} iconAudio - текущее состояние иконки микрофона в зависимости от статуса
 * @vue-computed {String} iconVideo - текущее состояние иконки камеры в зависимости от статуса
 * @vue-event {Boolean} toggle-control - событие по которому нужно дать или забрать права на управление презентацией у ведущего
 */
export default {
  name: "SpeakerBroadcastParticipantsItem",
  components: {
    WsButton,
    WsIcon,
  },
  props: {
    participant: {
      type: Object,
      required: true,
    },
    currentControlUserId: {
      type: Number,
      default: 0,
    },
    owner: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconAudio() {
      return `microphone${!this.participant.permissions.audio ? "-slash" : ""}`;
    },
    iconVideo() {
      return `video${!this.participant.permissions.video ? "-slash" : ""}`;
    },
  },
  methods: {
    /**
     * Изменяет состояние права на управление презентацие у ведущего по средствам события в родительский компонент
     */
    toggleControl() {
      this.$emit("toggle-control", !this.participant.permissions.presentationControl);
    },
  },
};
</script>

<style scoped lang="less">
@import "~@/styles/_vars";

.broadcast-participant {
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;

  &:not(:last-child) {
    border-bottom: solid 1px @modal-block-border;
  }

  &__photo {
    position: relative;
    flex: 0 0 28px;
    width: 28px;
    height: 28px;
    margin-left: 6px;
    background-image: url("~@/assets/images/profile-no-photo.svg");
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    border-radius: 50%;

    &::before {
      position: absolute;
      top: -4px;
      left: -4px;
      z-index: 1;
      display: block;
      width: 36px;
      height: 36px;
      content: "";
      border: solid 2px @broadcast-participant-photo-border;
      border-radius: 50%;
      transition: all 0.2s ease-in;
    }

    &--speaking::before {
      border-color: @broadcast-participant-photo-border-active;
    }
  }

  &__name {
    flex: 1 1 auto;
    margin: 0 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__chip {
    flex: 0 0 80px;
    width: 80px;
    margin-right: 15px;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    color: @broadcast-participant-speaker-chip-color;
    text-align: center;
    background: @broadcast-participant-speaker-chip-bg;
    border-radius: 20px;
  }

  &__icon-btn {
    flex: 0 0 auto;
    font-size: 18px;
    line-height: 18px;
    color: @text-dark;

    &:not(:last-child) {
      margin-right: 15px;
    }

    &--muted {
      color: @text-pale-gray;
    }
  }

  &__button {
    --base-color: @broadcast-participant-photo-border-active;

    flex: 0 0 90px;
    width: 90px;
    font-weight: 600;
    border-radius: 5px;
  }
}
</style>
