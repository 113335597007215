<template>
  <div :class="$style.container">
    <!--@slot Контент который будет вставлен до списка навигации(прим: лого на спикере и кнопка през) -->
    <slot v-if="$scopedSlots.before" name="before"></slot>
    <!--@slot Список разделов в меню -->
    <slot name="navigation"></slot>
    <!--@slot Контент который будет вставлен после списка навигации(прим: бургер на спикере) -->
    <slot v-if="$scopedSlots.after" name="after"></slot>
  </div>
</template>

<script>
export default {
  name: "LayoutNavigation",
};
</script>

<style lang="scss" module>
.container {
  display: grid;
  grid-template-areas: "before navigation after";
  grid-template-columns: auto 1fr auto;
  width: 100%;
  height: $navigation-height;
  background-color: var(--base-color);
}
</style>
