<template>
  <container-broadcast-settings
    v-if="(hasSidebar || hasSheet) && isBroadcastEnabled"
    ref="userDeviceSettings"
    #default="{ audioDevices, videoDevices, resolutions, pending, error }"
    :disabled="!isNeedMediaDevices"
    :conference-type="isConferenceType"
  >
    <broadcast-panel
      :on-air="onAir && !isVideoType"
      :sidebar="hasSidebar"
      :expanded="broadcastPanelExpanded"
      @opened="broadcastPanelExpanded = true"
      @closed="broadcastPanelExpanded = false"
    >
      <template #header>
        <speaker-broadcast-state
          :on-air="onAir"
          :speakers-count="speakersCount"
          :users-count="viewersCount"
          :hands-up-count="participantsRaiseHandCount"
          :disable-counter="!isConferenceType"
          :connection-quality="connectionQuality"
        />
      </template>
      <template v-if="showBroadcastPlayer || streamIsBlocked" #player>
        <broadcast-blocked-message v-if="streamIsBlocked" />
        <broadcast-player
          v-else
          v-bind="playersConfig"
          @failed="handleBroadcastSessionFailed"
          @set-stream-name="handleSetStreamName"
          @participant-join="participantJoin"
          @participant-left="participantLeft"
          @participant-published="participantPublished"
          @participant-off-video="participantOffVideo"
          @participant-off-audio="participantOffAudio"
          @set-capture-stream-name="handleSetCaptureStreamName"
          @video-muted="setSpeakerState"
          @screen-saver-hide="hideScreenSaverHandler"
          @error="errorHandler"
          @connection-quality-update="connectionQualityUpdateHandler"
          @publishing="publishingHandler"
        />
      </template>
      <template #controls>
        <broadcast-controls
          :audio-devices="audioDevices"
          :video-devices="videoDevices"
          :recording-types="recordingTypes"
          :pending="pending"
          :prepared="prepared"
          :on-air="onAir"
          :blocked="(!!error.type && !isExternalStream) || streamIsBlocked || displayScreenSaver"
          :servers="broadcastServers"
          :rtmp-servers="rtmpServers"
          :resolutions="resolutions"
          :record-type.sync="recordType"
          :typical-type="isTypicalType"
          :one2-many-type="isOne2ManyType"
          :video-type="isVideoType"
          :buffering="buffering"
          :screen-saver="screenSaver"
          :has-screen-saver="!!screenSaverSrc"
          :go-on-air-blocked="goOnAirBlocked"
          @set-audio="setSettings('Audio', $event)"
          @set-video="setSettings('Video', $event)"
          @set-server="setSettings('streamServer', $event.value)"
          @set-rtmp="setSettings('rtmpServer', $event)"
          @set-resolution="setSettings('resolution', $event)"
          @set-buffering="setSettings('buffering', $event)"
          @set-screen-saver="setSettings('screenSaver', $event)"
          @toggle-player="togglePlayerPrepared"
          @go-on-air="broadcastGoOnAir"
          @exit-from-on-air="broadcastExitFromOnAir"
        />
      </template>
      <template :slot="participantsFullMode ? 'popup' : 'speakers'">
        <speaker-broadcast-participants
          v-if="onAir && isConferenceType"
          :participants="participantsWithStatuses"
          :viewers-count="viewersCount"
          :speaker="speakerBroadcastData"
          :full-mode="participantsFullMode"
          @exit-full-mode="participantsFullMode = false"
          @off-audio="participantOffAudio"
          @off-video="participantOffVideo"
          @toggle-control="participantToggleControl"
          @disconnect="disconnectParticipant"
          @accept="acceptJoinToAirHandler"
          @show-all="participantsFullMode = true"
        />
      </template>
      <template v-if="hasSidebar && currentPresentationName" #info>
        <speaker-broadcast-presentation-info
          :title="currentPresentationName"
          :slides-current-position="currentPresentationSlidePosition"
          :slides-count="currentPresentationSlidesCount"
          @choose-another="chooseAnotherPresentation"
        />
      </template>
      <template>
        <smart-modal
          v-if="onAir && disconnectParticipantId"
          :no-blocking="$mqLaptop"
          :text-confim="$t('common.delete')"
          @close="participantDisconnectConfirm"
          @cancel="participantDisconnectConfirm"
          @confirm="participantDisconnectConfirm(true)"
        >
          <template #header>
            {{ $t("broadcast.takeOffAirQuestionStart") }}
            <em>{{ disconnectParticipantName }}</em>
            {{ $t("broadcast.takeOffAirQuestionEnd") }}
          </template>
        </smart-modal>
        <speaker-broadcast-join-on-air-requests
          v-if="onAir"
          v-show="showJoinToAirRequests"
          v-fullscreen.attach="'broadcast'"
          :requesters="requesters"
          :desktop="$mqLaptop"
          @close="joinToAirCloseRequestsClose"
          @confirm="acceptJoinToAirHandler"
        />
      </template>
    </broadcast-panel>
  </container-broadcast-settings>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { auth, room, presentation, socket, broadcast } from "@/store/modules/store.namespaces";
import {
  CURRENT_PRESENTATION_NAME,
  CURRENT_PRESENTATION_SELECTED_SLIDE_POS,
  CURRENT_PRESENTATION_SLIDES_COUNT,
} from "@/store/modules/presentation/getter-types";
import { SET_SOCKET_OBJ } from "@/store/modules/socket/mutation-types";
import {
  STREAM_TYPES,
  SEND_PULSE_INTERVAL,
  RTMP_SERVERS,
  SCREEN_SAVER_TIMEOUT,
  OPTION_EXTERNAL_STREAM,
  ConnectionQuality,
} from "@/constants/broadcast/broadcast-const";
import {
  BROADCAST_RESET_STATE,
  BROADCAST_SET_STATE_PROP,
} from "@/store/modules/broadcast/mutation-types";
import {
  BROADCAST_PLAYER_TYPE,
  BROADCAST_SERVERS,
  BROADCAST_URL,
  EXTERNAL_STREAM_URL,
  FACECAST_URL,
  IS_BROADCAST_ENABLED,
  IS_CONFERENCE_TYPE,
  IS_EXTERNAL_STREAM,
  IS_FACECAST_TYPE,
  IS_MP4_TYPE,
  IS_NEED_MEDIA_DEVICES,
  IS_ONE_2_MANY_TYPE,
  IS_TYPICAL_TYPE,
  IS_VIDEO_TYPE,
  IS_VIMEO_TYPE,
  IS_YOUTUBE_TYPE,
  SCREEN_SAVER_SRC,
  SPEAKER_PLAYER_CONSTRAINTS,
  VIMEO_BROADCAST_URL,
  YT_BROADCAST_URL,
} from "@/store/modules/broadcast/getter-types";
import { CLEAR_LIST_OF_LEADING, UPDATE_LEADING } from "@/store/modules/broadcast/action-types";
import { AVAILABLE_ROOM_MODULES } from "@/store/modules/common/getter-types";
import { SpeakerRouteName } from "@/constants/router/router-const";

import ContainerBroadcastSettings from "@/containers/broadcast/ContainerBroadcastSettings";
import BroadcastPanel from "@/components/common/broadcast/BroadcastPanel";
import SpeakerBroadcastState from "@/components/speaker/broadcast/SpeakerBroadcastState";
import BroadcastControls from "@/components/common/broadcast/BroadcastControls";
import SpeakerBroadcastParticipants from "@/components/speaker/broadcast/SpeakerBroadcastParticipants";
import SpeakerBroadcastPresentationInfo from "@/components/speaker/broadcast/SpeakerBroadcastPresentationInfo";
import useEventRecording from "@/components/speaker/broadcast/mixins/useEventRecording";
import BroadcastPlayer from "@/components/common/broadcast/BroadcastPlayerByType";
import SpeakerBroadcastJoinOnAirRequests from "@/components/speaker/broadcast/SpeakerBroadcastJoinOnAirRequests";
import SmartModal from "@/components/common/modals/SmartModal";
import BroadcastBlockedMessage from "@/components/common/broadcast/BroadcastBlockedMessage";

export default {
  name: "ContainerBroadcastSpeakerPanel",
  components: {
    BroadcastBlockedMessage,
    BroadcastPlayer,
    ContainerBroadcastSettings,
    BroadcastPanel,
    SpeakerBroadcastState,
    BroadcastControls,
    SpeakerBroadcastParticipants,
    SpeakerBroadcastPresentationInfo,
    SpeakerBroadcastJoinOnAirRequests,
    SmartModal,
  },
  mixins: [useEventRecording],
  data() {
    return {
      broadcastSendStateTimer: 0,
      broadcastPanelExpanded: false,
      participantsFullMode: false,
      disconnectParticipantId: 0,
      disconnectParticipantName: "",
      showJoinToAirRequests: false,
      rtmpServers: RTMP_SERVERS,
      connectionQuality: ConnectionQuality.Off,
      goOnAirBlocked: false,
    };
  },
  computed: {
    ...mapState(auth, ["user"]),
    ...mapState(room, ["roomNumber", "interfaceLanguage"]),
    ...mapGetters(presentation, {
      currentPresentationName: CURRENT_PRESENTATION_NAME,
      currentPresentationSlidePosition: CURRENT_PRESENTATION_SELECTED_SLIDE_POS,
      currentPresentationSlidesCount: CURRENT_PRESENTATION_SLIDES_COUNT,
    }),
    ...mapState(broadcast, [
      "devices",
      "resolution",
      "prepared",
      "onAir",
      "streamName",
      "streamCaptureName",
      "streamServer",
      "rtmpServer",
      "participants",
      "raiseHandIds",
      "onAirIds",
      "viewers",
      "buffering",
      "speakerState",
      "selectedVideo",
      "screenSaver",
      "displayScreenSaver",
      "permissions",
    ]),
    ...mapGetters(broadcast, {
      isBroadcastEnabled: IS_BROADCAST_ENABLED,
      isTypicalType: IS_TYPICAL_TYPE,
      isExternalStream: IS_EXTERNAL_STREAM,
      isConferenceType: IS_CONFERENCE_TYPE,
      isFacecastType: IS_FACECAST_TYPE,
      isYtType: IS_YOUTUBE_TYPE,
      isVimeoType: IS_VIMEO_TYPE,
      isMp4Type: IS_MP4_TYPE,
      broadcastPlayerConstraints: SPEAKER_PLAYER_CONSTRAINTS,
      facecastUrl: FACECAST_URL,
      isOne2ManyType: IS_ONE_2_MANY_TYPE,
      externalStreamUrl: EXTERNAL_STREAM_URL,
      isNeedMediaDevices: IS_NEED_MEDIA_DEVICES,
      isVideoType: IS_VIDEO_TYPE,
      broadcastPlayerType: BROADCAST_PLAYER_TYPE,
      screenSaverSrc: SCREEN_SAVER_SRC,
      broadcastUrl: BROADCAST_URL,
      ytBroadcastUrl: YT_BROADCAST_URL,
      vimeoBroadcastUrl: VIMEO_BROADCAST_URL,
      servers: BROADCAST_SERVERS,
    }),
    ...mapGetters(room, {
      availableRoomModules: AVAILABLE_ROOM_MODULES,
    }),
    playersConfig() {
      return {
        playerType: this.broadcastPlayerType,
        playerProps:
          (this.isConferenceType && this.conferencePlayerProps) ||
          (this.isExternalStream && this.videoPlayerProps) ||
          (this.isOne2ManyType && this.one2ManyPlayerProps) ||
          (this.isTypicalType && this.rtmpPlayerProps) ||
          this.videoPlayerProps,
      };
    },
    conferencePlayerProps() {
      return {
        screenSaverSrc: this.screenSaverSrc,
        displayScreenSaver: this.displayScreenSaver,
        type: STREAM_TYPES.SPEAKER,
        resolution: this.resolution,
        constraints: this.broadcastPlayerConstraints,
        userName: this.user.fio || this.user.login,
        userAvatar: this.user.photoWithUrl,
        userId: this.user.id,
        room: this.roomNumber,
        urlServer: this.streamServer,
        streamCaptureName: this.streamCaptureName,
      };
    },
    one2ManyPlayerProps() {
      return {
        screenSaverSrc: this.screenSaverSrc,
        displayScreenSaver: this.displayScreenSaver,
        type: STREAM_TYPES.SPEAKER,
        room: this.roomNumber,
        userId: this.user.id,
        userAvatar: this.user.photoWithUrl,
        constraints: this.broadcastPlayerConstraints,
        resolution: this.resolution,
        onStopped: this.onStopped,
        urlServer: this.streamServer,
        onAir: this.onAir,
        streamCaptureName: this.streamCaptureName,
        record: this.isStreamRecording,
        onStopRecording: this.showRecordDownloadModal,
      };
    },
    rtmpPlayerProps() {
      return {
        ...this.one2ManyPlayerProps,
        rtmp: true,
        rtmpUrl: this.rtmpServer.name,
      };
    },
    videoPlayerProps() {
      return {
        youtube: this.isYtType,
        vimeo: this.isVimeoType,
        mp4: this.isMp4Type,
        facecast: this.isFacecastType,
        typical: this.isExternalStream,
        url:
          (this.isYtType && this.ytBroadcastUrl) ||
          (this.isVimeoType && this.vimeoBroadcastUrl) ||
          (this.isMp4Type && this.broadcastUrl) ||
          (this.isFacecastType && this.facecastUrl) ||
          "",
        multilingual: this.availableRoomModules.streamMultilingual,
        screenSaverSrc: this.screenSaverSrc,
        displayScreenSaver: this.displayScreenSaver,
      };
    },
    hasSidebar() {
      return this.$mqLaptop;
    },
    hasSheet() {
      return !this.hasSidebar;
    },
    showBroadcastPlayer() {
      return this.prepared;
    },
    speakerBroadcastData() {
      return {
        id: this.user.id,
        name: this.user.fio,
        photo: this.user.photoWithUrl,
      };
    },
    participantsRaiseHandCount() {
      return this.raiseHandIds.length;
    },
    speakersCount() {
      return this.onAirIds.length;
    },
    participantsWithStatuses() {
      return this.participants.map(p => {
        return {
          ...p,
          onAir: this.onAirIds.includes(p.user_id),
          raiseHand: this.raiseHandIds.includes(p.user_id),
        };
      });
    },
    requesters() {
      return this.participants.filter(p => this.raiseHandIds.includes(p.user_id));
    },
    viewersCount() {
      return this.viewers.length;
    },
    broadcastServers() {
      const servers = this.servers.map(server => ({
        label: server.label[this.interfaceLanguage],
        value: server.value,
      }));
      if (this.isConferenceType) {
        return servers.filter(s => !s.value.includes("fast"));
      }
      return servers;
    },
    recordingTypes() {
      if (this.isOne2ManyType || this.isTypicalType) {
        return this.recordTypes.slice(0, 2);
      }
      return [];
    },
    streamIsBlocked() {
      return (
        (this.isConferenceType && this.$isCordovaIOS) ||
        (this.isConferenceType && this.$isIOSNotSupportWebRTC)
      );
    },
  },
  watch: {
    raiseHandIds(newVal, oldVal) {
      if (newVal.length > oldVal.length) {
        this.showJoinToAirRequests = true;
      }
    },
  },
  mounted() {
    if (this.hasSidebar) {
      this.broadcastPanelExpanded = true;
    }
  },
  beforeDestroy() {
    clearInterval(this.broadcastSendStateTimer);
  },
  methods: {
    ...mapMutations(broadcast, {
      setBroadcastStateProp: BROADCAST_SET_STATE_PROP,
      resetBroadcastState: BROADCAST_RESET_STATE,
    }),
    ...mapMutations(socket, {
      setSocketObj: SET_SOCKET_OBJ,
    }),
    ...mapActions(broadcast, {
      updateLeading: UPDATE_LEADING,
      clearListOfLeading: CLEAR_LIST_OF_LEADING,
    }),
    getSocketObj(state = false) {
      const streamServer = this.streamServer.includes("fast")
        ? "wss://cdn-fast.whenspeak.ru:8443"
        : this.streamServer;
      return {
        type: "stream",
        launched: state,
        stream_data: {
          playerType: this.broadcastPlayerType,
          buffering: state && !this.isExternalStream ? this.buffering : true,
          name: state && !this.isExternalStream ? this.streamName : "",
          captureName: state ? this.streamCaptureName : "",
          server: state ? streamServer : "",
          displayScreenSaver: this.displayScreenSaver,
          // TODO: ни где не обрабатывается, нужно убрать
          permissions: {
            presentationControl: this.permissions.presentationControl,
          },
          speaker: {
            avatar: this.user.photoWithUrl,
            videoMuted: this.speakerState?.videoMuted,
            resolution: this.resolution || null,
          },
        },
      };
    },
    setSpeakerState(video) {
      this.setBroadcastStateProp({
        speakerState: {
          avatar: this.user.photoWithUrl,
          videoMuted: video,
        },
      });
      if (this.onAir) {
        this.setSocketObj(this.getSocketObj(true));
      }
    },
    joinToAirCloseRequestsClose() {
      this.showJoinToAirRequests = false;
    },
    async acceptJoinToAirHandler(id) {
      const candidate = this.participants.find(p => p.user_id === id);
      candidate.permissions.canPresent = true;
      await this.updateLeading(candidate);
      this.setBroadcastStateProp({
        raiseHandIds: this.raiseHandIds.filter(rhId => rhId !== id),
      });
    },
    disconnectParticipant(id) {
      const candidate = this.participants.find(p => p.user_id === id);
      this.disconnectParticipantName = candidate.name;
      this.disconnectParticipantId = id;
    },
    participantToggleControl(presentationControl, userId) {
      const participantId = userId;
      const candidate = this.participants.find(p => p.user_id === participantId);
      candidate.permissions.presentationControl = presentationControl;
      this.updateLeading(candidate);
    },
    participantOffAudio(id) {
      let participantId = id;
      if (typeof participantId === "string") {
        participantId = Number.isNaN(participantId)
          ? this.processParticipantName(participantId).id
          : +participantId;
      }
      const candidate = this.participants.find(p => p.user_id === participantId);
      if (candidate?.permissions.audio) {
        candidate.permissions.audio = false;
        this.updateLeading(candidate);
      }
    },
    participantOffVideo(id) {
      let participantId = id;
      if (typeof participantId === "string") {
        participantId = Number.isNaN(participantId)
          ? this.processParticipantName(participantId).id
          : +participantId;
      }
      const candidate = this.participants.find(p => p.user_id === participantId);
      if (candidate?.permissions.video) {
        candidate.permissions.video = false;
        this.updateLeading(candidate);
      }
    },
    participantDisconnectConfirm(remove) {
      if (remove) {
        const candidate = this.participants.find(p => p.user_id === this.disconnectParticipantId);
        candidate.permissions.canPresent = false;
        this.updateLeading(candidate);
      }
      this.disconnectParticipantId = 0;
      this.disconnectParticipantName = "";
    },
    chooseAnotherPresentation() {
      this.$router.push({ name: SpeakerRouteName.Presentations });
    },
    async broadcastPrepareForAir() {
      if (!this.prepared && !this.devices.audio.length && !this.devices.video.length) {
        await this.$refs.userDeviceSettings.init();
      }
    },
    togglePlayerPrepared() {
      if (this.prepared) {
        this.handleSetStreamName("");
        if (this.goOnAirBlocked) {
          this.goOnAirBlocked = false;
        }
      }
      if (
        (this.isOne2ManyType || this.isTypicalType || this.isConferenceType) &&
        !this.isExternalStream &&
        !this.prepared
      ) {
        this.goOnAirBlocked = true;
      }
      this.setBroadcastStateProp({
        prepared: !this.prepared,
      });
    },
    broadcastGoOnAir() {
      this.setBroadcastStateProp({
        onAir: true,
        constraints: this.broadcastPlayerConstraints,
        displayScreenSaver: this.screenSaver,
      });
      window.addEventListener("beforeunload", this.beforePageUnloadHandler);
      window.addEventListener("unload", this.unloadPageHandler);
      this.setSocketObj(this.getSocketObj(true));
      this.broadcastSendStateTimer = setInterval(() => {
        this.setSocketObj(this.getSocketObj(true));
      }, SEND_PULSE_INTERVAL);

      if (this.isExternalStream || this.isYtType || this.isMp4Type || this.isFacecastType) {
        this.connectionQuality = ConnectionQuality.OnAir;
      } else {
        this.connectionQuality = ConnectionQuality.Perfect;
      }
    },
    broadcastExitFromOnAir(force = false) {
      if (this.hasSheet) {
        this.broadcastPanelExpanded = false;
      }
      clearInterval(this.broadcastSendStateTimer);
      this.broadcastSendStateTimer = 0;
      if (this.isConferenceType) {
        this.clearListOfLeading();
      }
      if (this.screenSaver && !force) {
        this.setBroadcastStateProp({
          displayScreenSaver: true,
        });
        this.setSocketObj(this.getSocketObj(true));
        setTimeout(() => {
          this.exitFromOnAirInternal();
        }, SCREEN_SAVER_TIMEOUT);
      } else {
        this.exitFromOnAirInternal();
      }
    },
    exitFromOnAirInternal() {
      this.resetBroadcastState();
      this.setSocketObj(this.getSocketObj());
      this.connectionQuality = ConnectionQuality.Off;
      // restart preview for desktop (for mobile it restart on panel expand)
      if (this.hasSidebar) {
        setTimeout(() => {
          this.broadcastPrepareForAir();
        }, SEND_PULSE_INTERVAL);
      }
      window.removeEventListener("beforeunload", this.beforePageUnloadHandler);
      window.removeEventListener("unload", this.unloadPageHandler);
    },
    /**
     * Prevent unload page event when on air
     *
     * @param {Event} event
     */
    beforePageUnloadHandler(event) {
      if (this.onAir) {
        event.returnValue = this.$t("Translation.reload_page");
        return this.$t("Translation.reload_page");
      }
      return "";
    },
    /**
     * If user close page and not stop broadcast
     */
    async unloadPageHandler() {
      if (this.onAir) {
        await this.broadcastExitFromOnAir(true);
      }
    },
    handleBroadcastSessionFailed() {
      if (this.goOnAirBlocked) {
        this.goOnAirBlocked = false;
      }
      this.broadcastExitFromOnAir(true);
      this.setBroadcastStateProp({
        prepared: false,
      });
    },
    async setSettings(key, value) {
      const stateKey = ["Video", "Audio"].includes(key) ? `selected${key}` : key;
      this.setBroadcastStateProp({
        [stateKey]: value,
      });
      if (key === "Video" && value.deviceId !== OPTION_EXTERNAL_STREAM) {
        await this.$refs.userDeviceSettings?.checkAvailableResolution(value);
      }
      if (key === "Audio") {
        await this.$refs.userDeviceSettings?.checkAvailableAudio(value);
      }
      if (key === "streamServer") {
        this.serverUrl = value;
      }
    },
    handleSetStreamName(name) {
      this.setBroadcastStateProp({
        streamName: name,
      });
    },
    handleSetCaptureStreamName(name) {
      this.setBroadcastStateProp({
        streamCaptureName: name,
      });
    },
    participantJoin(participantName) {
      const user = this.processParticipantName(participantName);
      this.setBroadcastStateProp({
        viewers: [...this.viewers, user],
      });
    },
    participantPublished(participantName) {
      const user = this.processParticipantName(participantName);
      const { onAirIds } = this;
      onAirIds.push(user.id);
      this.setBroadcastStateProp({
        onAirIds,
        viewers: this.viewers.filter(v => v.id !== user.id),
      });
    },
    /**
     * Убираем ид участника из списка ид тех кто в эфире
     * Так же забираем права листать презентацию если они были, а участник прервал свое вещание
     *
     * @param {string} participantName - имя которые мы формируем когда участник подключается к трасняляции(смотрит или вещает)
     * @param {boolean} leading - являлся ли участник ведущим
     */
    participantLeft(participantName, leading = false) {
      const user = this.processParticipantName(participantName);
      if (leading) {
        const onAirIds = this.onAirIds.filter(id => id !== user.id);
        this.setBroadcastStateProp({
          onAirIds,
          viewers: [...this.viewers, user],
        });
        const participant = this.participantsWithStatuses.find(
          participant =>
            participant.user_id === user.id && participant.permissions.presentationControl,
        );
        if (participant) {
          this.participantToggleControl(false, participant.user_id);
        }
      } else {
        this.setBroadcastStateProp({
          viewers: this.viewers.filter(v => v.id !== user.id),
        });
      }
    },
    processParticipantName(name) {
      const type = name.match(/(#t{([A-z]*)})/)[2].replace("__", "");
      const id = name.match(/(#id{(\d*)})/)[2];
      return {
        type,
        id: +id,
      };
    },
    hideScreenSaverHandler() {
      this.setBroadcastStateProp({
        displayScreenSaver: false,
      });
      this.setSocketObj(this.getSocketObj(true));
    },
    errorHandler() {
      this.broadcastExitFromOnAir(true);
    },
    connectionQualityUpdateHandler(quality) {
      if (this.onAir) {
        this.connectionQuality = quality;
      }
    },
    publishingHandler() {
      this.goOnAirBlocked = false;
    },
  },
};
</script>
