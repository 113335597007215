<template>
  <div :class="$style.wrapper" class="text-md">
    <ws-icon
      v-if="stateByQuality.icon.name"
      data-testid="qualityIcon"
      v-bind="{ [stateByQuality.icon.type]: true }"
      sm
      :class="[$style.icon, $style[`icon_${stateByQuality.color}`]]"
      >{{ stateByQuality.icon.name }}</ws-icon
    >
    {{ stateByQuality.text }}
  </div>
</template>

<script>
import WsIcon from "@/components/base/WsIcon";
import { ConnectionQuality } from "@/constants/broadcast/broadcast-const";

/**
 * Компонент отображающий состояние качества соединения для стрима флешфонера.
 * Если стрим не флешфонер то будет стандартная отображение иконкой circle с типом duotone
 */
export default {
  name: "BroadcastConnectionQuality",
  components: {
    WsIcon,
  },
  props: {
    /**
     * Текущее качество соединения
     * Определяется константой ConnectionQuality
     * @values OFF, UNKNOWN, BAD, GOOD, PERFECT
     */
    quality: {
      type: String,
      default: ConnectionQuality.Off,
    },
  },
  computed: {
    state() {
      return {
        [ConnectionQuality.Off]: this.stateFactory(this.$t("broadcast.notOnAir"), "circle"),
        [ConnectionQuality.Unknown]: this.stateFactory(
          this.$t("broadcast.noConnection"),
          "signal-alt",
        ),
        [ConnectionQuality.Bad]: this.stateFactory(
          this.$t("broadcast.onAir"),
          "signal-alt-1",
          "bad",
        ),
        [ConnectionQuality.Good]: this.stateFactory(
          this.$t("broadcast.onAir"),
          "signal-alt-3",
          "good",
        ),
        [ConnectionQuality.Perfect]: this.stateFactory(
          this.$t("broadcast.onAir"),
          "signal-alt",
          "perfect",
        ),
        [ConnectionQuality.OnAir]: this.stateFactory(this.$t("broadcast.onAir"), "circle", "onAir"),
      };
    },
    stateByQuality() {
      return this.state[this.quality];
    },
  },
  methods: {
    stateFactory(text, name = "", color = "off") {
      return {
        icon: {
          type: "duotone",
          name,
        },
        color,
        text,
      };
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 8px;

  &_off {
    color: $gray;
  }

  &_bad,
  &_onAir {
    color: $danger;
  }

  &_good {
    color: #f79843;
  }

  &_perfect {
    color: #4bcb34;
  }
}
</style>
