import { RECORD_MESSAGES, RecordTypes } from "@/constants/broadcast/broadcast-const";

export default {
  data() {
    return {
      recordType: {
        label: this.$t("broadcast.recordTypes.off"),
        id: RecordTypes.Off,
        disabled: false,
      },
      recordingChannel: null,
      serverUrl: "",
    };
  },
  computed: {
    recordTypes() {
      return [
        {
          label: this.$t("broadcast.recordTypes.off"),
          id: RecordTypes.Off,
          disabled: false,
        },
        {
          label: this.$t("broadcast.recordTypes.stream"),
          id: RecordTypes.Stream,
          disabled: this.isConferenceType && !this.$hasScreenCapture,
        },
        {
          label: this.$t("broadcast.recordTypes.event"),
          id: RecordTypes.Event,
          disabled: !this.$hasScreenCapture,
        },
      ];
    },
    isStreamRecording() {
      return this.recordType.id === RecordTypes.Stream;
    },
    isEventRecording() {
      return this.recordType.id === RecordTypes.Event;
    },
    isRecordingOff() {
      return this.recordType.id === RecordTypes.Off;
    },
  },
  methods: {
    startEventRecord() {
      this.openRecordWindow();
      this.createRecordingChannel();
    },
    stopEventRecord(recordName) {
      this.closeRecordWindow();
      this.closeRecordingChannel();
      this.recordType = this.recordTypes.find(type => type.id === RecordTypes.Off);
      this.showRecordDownloadModal(recordName);
      this.serverUrl = "";
    },
    createRecordingChannel() {
      if (!this.recordingChannel) {
        this.recordingChannel = new BroadcastChannel("recordingChannel");
      }
    },
    closeRecordingChannel() {
      if (this.recordingChannel) {
        this.recordingChannel.close();
        this.recordingChannel = null;
      }
    },
    openRecordWindow() {
      const eventRecordingUrl = this.$router.resolve({
        name: "eventRecord",
        params: {
          id: this.$route.params.id,
        },
        query: {
          typeId: this.recordType.id,
        },
      });
      window.open(
        eventRecordingUrl.href,
        undefined,
        "noopener",
      );
    },
    closeRecordWindow() {
      this.recordingChannel?.postMessage({
        type: RECORD_MESSAGES.CLOSE_WINDOW,
        message: {
          roomId: this.$route.params.id,
        },
      });
    },
    showRecordDownloadModal(recordName) {
      if (!recordName) return;
      const link = this.getRecordLink(recordName);
      this.$dialog.alert(
        {
          title: this.$t("broadcast.recordDownloadTitle"),
          body: `${this.$t(
            "broadcast.recordDownloadText",
          )}<a href="${link}" download="${recordName}" target="_blank">${this.$t(
            "broadcast.recordDownload",
          )}</a>`,
        },
        {
          html: true,
          okText: this.$t("common.close"),
        },
      );
    },
    getRecordLink(recordName) {
      if (!recordName) return "";
      return `https://${this.serverUrl.replace(
        /wss:\/\/|:\d+/g,
        "",
      )}/client/records/${recordName}`;
    },
  },
};
