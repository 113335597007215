<template>
  <div class="nav-side-bar__container">
    <transition name="fadeInRight">
      <div v-show="expanded" class="nav-side-bar">
        <div>
          <ws-button fab class="nav-side-bar__close" @click="toggleBurgerMenu">
            <ws-icon light>times-circle</ws-icon>
          </ws-button>
          <landing-lang-selector />
          <div class="nav-side-bar__logo"></div>
          <div v-if="userName" class="nav-side-bar__hello-message">
            {{ $t("navigation.hello") }},
            <br />
            {{ userName }}
          </div>

          <router-link
            v-if="changeRoomButtonVisible"
            v-slot="{ navigate }"
            to="/speaker/rooms"
            class="nav-side-bar__enter-to-room"
          >
            <ws-button color="primary" outlined lg @click="goToRoomClickHandler(navigate)">{{
              $t("navigation.goToRoom")
            }}</ws-button>
          </router-link>

          <form v-if="hasNotificationModule" class="nav-side-bar__send-message">
            <div class="nav-notify__title">
              {{ $t("navigation.sendPush") }}
            </div>
            <ws-select
              v-model="notificationType"
              :options="notificationTypes"
              class="nav-notify__select"
            />

            <textarea
              v-if="notificationType.id === 0"
              :value="notification"
              name="notification"
              class="nav-notify__message"
              maxlength="250"
              :placeholder="$t('navigation.sendPush')"
              @input="notification = $event.target.value"
            />
            <p v-if="notificationType.id === 0">
              {{ $t("navigation.commonPushMaxLengthWarning") }}
            </p>

            <jodit-editor
              v-if="notificationType.id === 1"
              :key="htmlEditor.key"
              v-model.trim="notification"
              :config="htmlEditor.config"
            />

            <ws-button
              lg
              color="primary"
              outlined
              class="nav-notify__button"
              :disabled="
                !notification.trim() || (notificationType.id === 0 && notification.length > 250)
              "
              @click.prevent="setPush"
            >
              {{ $t("navigation.send") }}
            </ws-button>

            <ws-button
              lg
              color="primary"
              outlined
              class="nav-notify__clear-push-button"
              @click="clearLastPush"
            >
              {{ $t("navigation.clearLastPush") }}
            </ws-button>
          </form>

          <form v-if="hasTimerModule" class="nav-side-bar__timer">
            <div class="nav-timer__title">
              {{ $t("navigation.timer") }}
              <span v-show="timer >= 1"> ({{ $t("navigation.minutes") }}: {{ timer }}) </span>
            </div>
            <input
              v-model.number="timer"
              type="number"
              class="nav-timer__count"
              placeholder="0"
              min="0"
              max="999"
            />
            <ws-button
              color="primary"
              class="nav-timer__button"
              font-size="xl"
              :disabled="disabledButtonStartTimer"
              @click.prevent="setTime"
            >
              {{ $t("navigation.start") }}
            </ws-button>
            <div v-if="successTimer" class="nav-timer__success">
              {{ $t("navigation.timerIsSet") }}
              <br />
              ({{ $t("navigation.minutesQuantity") }}: {{ successTimer }})
            </div>
          </form>
        </div>
        <div>
          <ws-button
            lg
            font-size="xl"
            color="primary"
            outlined
            data-testid="logout"
            @click="logout"
          >
            {{ $t("navigation.exit") }}
            <ws-icon xl regular>sign-out</ws-icon>
          </ws-button>
          <span class="nav-side-bar__version">
            {{ $t("common.version") }}
          </span>
        </div>
      </div>
    </transition>

    <transition name="fadeIn">
      <div v-show="expanded" class="nav-side-bar__overlay" @click="toggleBurgerMenu"></div>
    </transition>
  </div>
</template>

<script>
import LandingLangSelector from "@/components/common/landing/LandingLangSelector";
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";
import WsSelect from "@/components/base/WsSelect";
import { JoditEditor } from "jodit-vue";
import { room, socket } from "@/store/modules/store.namespaces";
import { mapState, mapMutations } from "vuex";
import { SET_SOCKET_OBJ } from "@/store/modules/socket/mutation-types";

export default {
  name: "SpeakerSidebar",
  components: {
    LandingLangSelector,
    WsButton,
    WsIcon,
    WsSelect,
    JoditEditor,
  },
  props: {
    disabledButtonStartTimer: {
      type: Boolean,
      default: false,
    },
    userName: {
      type: String,
      default: "",
    },
    successTimer: {
      type: Number,
      default: 0,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    changeRoomButtonVisible: {
      type: Boolean,
      default: false,
    },
    hasNotificationModule: {
      type: Boolean,
      default: false,
    },
    hasTimerModule: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      notificationType: {
        label: this.$t("navigation.commonPush"),
        id: 0,
      },
      notification: "",
      timer: 0,
      // Конфигурация html-редактора
      htmlEditor: {
        // Ключ выбранной версии, используется для повторного рендера компонента
        key: localStorage.getItem("interfaceLang") === "eng" ? 1 : 0,
        config: {
          // Выбранный пользователем язык
          language: localStorage.getItem("interfaceLang") === "eng" ? "en" : "ru",
        },
      },
    };
  },
  computed: {
    notificationTypes() {
      return [
        {
          label: this.$t("navigation.commonPush"),
          id: 0,
        },
        {
          label: this.$t("navigation.importantPush"),
          id: 1,
        },
      ];
    },
    ...mapState(room, ["interfaceLanguage"]),
  },
  watch: {
    timer(newVal) {
      if (newVal) {
        this.$emit("reset-timer", false);
      }
    },
    /**
     * Метод слежения за изменением языка.
     *
     * @param {string} lang - выбранный пользователем язык (eng/rus)
     */
    interfaceLanguage(lang) {
      if (lang === "eng") {
        this.htmlEditor.config.language = "en";
        this.htmlEditor.key = 1;
      } else {
        this.htmlEditor.config.language = "ru";
        this.htmlEditor.key = 0;
      }
    },
  },
  methods: {
    ...mapMutations(socket, {
      setSocketObj: SET_SOCKET_OBJ,
    }),

    toggleBurgerMenu() {
      this.$emit("toggle-burger");
    },
    setPush() {
      const data = {
        notification: this.notification,
        type: this.notificationType.id,
      };
      this.$emit("set-push", data);
      this.notification = "";
    },

    /**
     * Метод очистки последнего уведомления.
     */
    clearLastPush() {
      this.setSocketObj({
        type: "clearLastNotification",
        obj: {},
      });
    },

    setTime() {
      this.$emit("set-time", this.timer);
      this.timer = 0;
    },
    logout() {
      this.$emit("logout");
    },
    goToRoomClickHandler(navigate) {
      this.toggleBurgerMenu();
      navigate(this.$route);
    },
  },
};
</script>

<style scoped lang="less">
.nav-side-bar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 320px;
  height: 100%;
  padding: 39px 30px 27px 27px;
  overflow-y: auto;
  background: #fff;

  &__close {
    position: absolute;
    top: 38px;
    right: 29px;
    font-size: 25px;
  }

  &__logo {
    width: 116px;
    height: 99px;
    margin-left: 11px;
    background: url("~@/assets/images/logo_white.png") no-repeat center;
    background-size: contain;
  }

  &__hello-message {
    margin-top: 52px;
    margin-bottom: 26px;
    font-size: 21px;
  }

  &__enter-to-room {
    margin-bottom: 37px;
  }

  &__send-message {
    overflow: hidden;
  }

  &__timer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__version {
    display: block;
    margin-top: 8px;
    font-size: 14px;
    line-height: 17px;
    color: #979797;
    text-align: center;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
  }
}

.nav-timer {
  &__title {
    width: 100%;
    margin-bottom: 15px;
  }

  &__count {
    width: 60%;
    font-size: 32px;
    line-height: 54px;
    text-align: center;
    border: solid 1px #d4d4d4;
    border-radius: 4px;
    outline: none;
  }

  &__button {
    width: 35%;
    height: auto;
  }

  &__success {
    width: 100%;
    margin-top: 10px;
    font-size: 14px;
  }
}

.nav-notify {
  &__title {
    margin-bottom: 12px;
  }

  &__select {
    width: 100%;
    margin-bottom: 15px;
  }

  &__message {
    width: 100%;
    height: 159px;
    padding: 10px 12px;
    resize: none;
    background-color: #f8f8f8;
    border-radius: 4px;
    outline: none;

    &::placeholder {
      /* Chrome/Opera/Safari */
      color: #414141;
    }
  }

  &__button {
    float: right;
    min-width: 110px;
    margin: 10px 0 0 0;
  }

  &__clear-push-button {
    width: 100%;
    margin: 10px 0;
  }
}

.fadeInRight-enter-active,
.fadeInRight-leave-active {
  transition: 0.3s;
}

.fadeInRight-enter,
.fadeInRight-leave-to {
  opacity: 0;
  transform: translate(100%);
}

.fadeIn-enter-active,
.fadeIn-leave-active {
  transition: 0.3s;
}

.fadeIn-enter,
.fadeIn-leave-to {
  opacity: 0;
}
</style>
