<template>
  <navigation-scroll-arrows>
    <div :class="$style.list" class="flex">
      <navigation-link
        v-for="(item, idx) in items"
        :key="idx"
        :item="item"
        :class="$style.listItem"
      />
    </div>
  </navigation-scroll-arrows>
</template>

<script>
import NavigationScrollArrows from "./NavigationScrollArrows";
import NavigationLink from "./NavigationLink";

export default {
  name: "Navigation",
  components: {
    NavigationScrollArrows,
    NavigationLink,
  },
  props: {
    /**
     * Items includes navigate item as type {
     *   route: string,
     *   icon: string,
     *   text: string,
     *   counter: number,
     *   activeFunc: function,
     *   mq: array,
     *   callback: function
     * }
     */
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" module>
.list {
  box-sizing: content-box;
  height: $navigation-height;
  padding-bottom: 7px;
  overflow-x: auto;
  overflow-y: hidden;
}

.listItem {
  &:first-of-type {
    margin-left: auto;
  }

  &:last-of-type {
    margin-right: auto;
  }
}
</style>
