<template>
  <div class="broadcast-presentation-info">
    <h2 class="broadcast-presentation-info__title">
      {{ title }}
    </h2>

    <div class="broadcast-presentation-info__counter">
      {{ slidesCounter }}
    </div>

    <div
      v-show="inPresentation"
      class="broadcast-presentation-info__link"
      @click="$emit('choose-another')"
    >
      {{ $t("SpeakerPresentation.chooseAnother") }}
    </div>
  </div>
</template>

<script>
import { SpeakerRouteName } from "@/constants/router/router-const";

export default {
  name: "SpeakerBroadcastPresentationInfo",
  props: {
    title: {
      type: String,
      default: "",
    },
    slidesCount: {
      type: Number,
      default: 0,
    },
    slidesCurrentPosition: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    slidesCounter() {
      return `${this.slidesCurrentPosition} ${this.$t("SpeakerPresentation.from")} ${
        this.slidesCount
      }`;
    },
    inPresentation() {
      return this.$route.matched.some(route => route.name === SpeakerRouteName.Presentation);
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/styles/_vars";

.broadcast-presentation-info {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-row-gap: 7px;
  grid-column-gap: 15px;
  align-items: center;
  margin: 20px 15px;
  font-size: 15px;
  line-height: 18px;

  &__title {
    font-size: 20px;
    line-height: 24px;
    color: @text-dark;
  }

  &__counter {
    color: @text-gray;
  }

  &__link {
    color: @broadcast-link-accent-color;
    cursor: pointer;
  }
}
</style>
