<template>
  <div
    class="broadcast-controls"
    :class="{
      'broadcast-controls--on-air': onAir,
      'broadcast-controls--leading': leading,
    }"
  >
    <ws-button
      v-show="!onAir && !leading"
      class="broadcast-controls__button"
      :class="{
        'broadcast-controls__button--full-width': !prepared,
      }"
      :outlined="prepared"
      :disabled="blocked || pending || !availableGoOnAir"
      color="primary"
      fontSize="lg"
      block
      lg
      @click="$emit('toggle-player')"
    >
      <ws-icon light class="broadcast-controls__button-icon">bolt</ws-icon>
      {{ !prepared ? $t("broadcast.captureVideo") : $t("broadcast.stopCaptureVideo") }}
    </ws-button>
    <ws-button
      v-if="onAir"
      class="broadcast-controls__button broadcast-controls__button--full-width"
      color="danger"
      fontSize="lg"
      block
      lg
      :disabled="blocked"
      @click="$emit('exit-from-on-air')"
    >
      {{ $t("broadcast.goOffAir") }}
    </ws-button>
    <template v-else-if="prepared">
      <!-- Было: :disabled="blocked || goOnAirBlocked || disableOnAirButton" -->
      <ws-button
        class="broadcast-controls__button"
        :class="{
          'broadcast-controls__button--full-width': leading,
        }"
        color="primary"
        block
        lg
        @click.once="$emit('go-on-air')"
      >
        <ws-icon v-if="prepared" class="broadcast-controls__button-icon">play</ws-icon>
        {{
          prepared
            ? $isLandscape && $mqMobile
              ? $t("broadcast.goOnAirShort")
              : $t("broadcast.goOnAir")
            : $t("broadcast.preparing")
        }}
      </ws-button>
    </template>
    <div v-if="pending" class="broadcast-controls__settings">
      <loader center />
    </div>
    <div v-else-if="!onAir || leading" ref="settings" class="broadcast-controls__settings">
      <settings-select
        class="broadcast-controls__select"
        :options="videoDevices"
        :selected="videoSelected"
        :disabled="disableControls"
        @input="setSettings('video', $event)"
      >
        <template slot="label">
          {{ $t("broadcast.camera") }}
        </template>
      </settings-select>
      <template v-if="!videoType">
        <settings-select
          class="broadcast-controls__select"
          :options="audioDevices"
          :selected="audioSelected"
          :disabled="disableControls"
          @input="setSettings('audio', $event)"
        >
          <template slot="label">
            {{ $t("broadcast.microphone") }}
          </template>
        </settings-select>
        <settings-select
          v-if="!onAir"
          class="broadcast-controls__select"
          :options="resolutions"
          :selected="resolutionSelected"
          :keys="resolutionKeys"
          :disabled="disableControls"
          @input="setSettings('resolution', $event)"
        >
          <template slot="label">
            {{ $t("broadcast.quality") }}
          </template>
        </settings-select>
        <settings-select
          v-if="!onAir && recordingTypes.length"
          class="broadcast-controls__select"
          :options="recordingTypes"
          :selected="recordType"
          :keys="recordTypeKeys"
          :disabled="disableControls"
          @input="$emit('update:recordType', $event)"
        >
          <template slot="label">
            {{ $t("broadcast.recordType") }}
          </template>
        </settings-select>
        <settings-select
          v-if="servers.length > 1"
          class="broadcast-controls__select"
          :options="servers"
          :selected="serverSelected"
          :keys="serverKeys"
          :disabled="disableControls"
          @input="setSettings('server', $event)"
        >
          <template slot="label">
            {{ $t("broadcast.servers") }}
          </template>
        </settings-select>
      </template>
      <div v-if="visibleAdditionalOpts" class="broadcast-controls__additional-opt">
        <div class="broadcast-controls__expander-wrapper">
          <span class="broadcast-controls__expander text-sm" @click="toggle">
            {{ $t("Translation.options") }}
            <ws-icon class="broadcast-controls__expander-icon" light>{{
              expanded ? "chevron-down" : "chevron-right"
            }}</ws-icon>
          </span>
        </div>
        <transition-expand>
          <div v-show="expanded" class="broadcast-controls__additional-opt-wrapper">
            <!-- Включение буфера -->
            <speaker-broadcast-checkbox
              class="broadcast-controls__opt-item"
              :label="$t('broadcast.useBuffer')"
              :hint="$t('broadcast.useBufferDescription')"
              :checked="buffering"
              :disabled="disableControls"
              @input="bufferingChangeHandler"
            />
            <!-- Включение буфера END -->

            <settings-select
              v-if="typicalType"
              class="broadcast-controls__select"
              :options="rtmpServers"
              :selected="rtmpSelected"
              :keys="serverKeys"
              :disabled="disableControls"
              @input="setSettings('rtmp', $event)"
            >
              <template slot="label">
                {{ $t("broadcast.rtmpServers") }}
              </template>
            </settings-select>
            <speaker-broadcast-checkbox
              v-if="hasScreenSaver"
              class="broadcast-controls__opt-item"
              :label="$t('broadcast.screenSaver')"
              :hint="$t('broadcast.screenSaverDescription')"
              :checked="screenSaver"
              :disabled="disableControls"
              @input="screenSaverChangeHandler"
            />
          </div>
        </transition-expand>
      </div>
    </div>
  </div>
</template>

<script>
import { RESOLUTIONS } from "@/constants/broadcast/broadcast-const";
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";
import Loader from "@/components/common/elements/Loader";
import SettingsSelect from "@/components/speaker/broadcast/components/SpeakerBroadcastSettingsSelect";
import SpeakerBroadcastCheckbox from "@/components/speaker/broadcast/components/SpeakerBroadcastCheckbox.vue";
import TransitionExpand from "@/components/common/transitions/TransitionExpand";

export default {
  name: "BroadcastControls",
  components: {
    WsIcon,
    WsButton,
    Loader,
    SettingsSelect,
    SpeakerBroadcastCheckbox,
    TransitionExpand,
  },
  props: {
    /**
     * Allowed audio devices array (microphones)
     *
     * @typedef {object[]} audioDevice              - microphone item
     * @param   {string}   audioDevices[].deviceId  - device id
     * @param   {string}   audioDevices[].label     - device name|title
     */
    audioDevices: {
      type: Array,
      default: () => [],
    },
    /**
     * Allowed video record devices array (cameras)
     *
     * @typedef {object[]} videoDevice              - camera item
     * @param   {string}   videoDevices[].deviceId  - device id
     * @param   {string}   videoDevices[].label     - device name|title
     */
    videoDevices: {
      type: Array,
      default: () => [],
    },
    /**
     * Speaker can start broadcasting
     */
    prepared: {
      type: Boolean,
      default: false,
    },
    /**
     * Wait initialize devices or error
     */
    pending: {
      type: Boolean,
      default: true,
    },
    /**
     * Stream successfully launched and available to participants.
     */
    onAir: {
      type: Boolean,
      default: false,
    },
    /**
     * Some kind of error occurred (during preparation or during the broadcast),
     * which blocks the stream.
     * This interferes with any management by the speaker.
     */
    blocked: {
      type: Boolean,
      default: false,
    },
    /**
     * Available resolutions
     */
    resolutions: {
      type: Array,
      default: () => [],
    },
    /**
     * Available servers
     */
    servers: {
      type: Array,
      default: () => [],
    },
    rtmpServers: {
      type: Array,
      default: () => [],
    },
    /**
     * Selected record type
     */
    recordType: {
      type: Object,
      default: () => ({}),
    },
    /**
     * Types of recording
     */
    recordingTypes: {
      type: Array,
      default: () => [],
    },
    typicalType: {
      type: Boolean,
      default: false,
    },
    one2ManyType: {
      type: Boolean,
      default: false,
    },
    videoType: {
      type: Boolean,
      default: false,
    },
    /**
     * If property true enabled rtmp for high latency, or low latency if false
     */
    buffering: {
      type: Boolean,
      default: false,
    },
    /**
     * Enable screen saver image
     */
    screenSaver: {
      type: Boolean,
      default: false,
    },
    /**
     * Has screen saver for broadcast in room options
     */
    hasScreenSaver: {
      type: Boolean,
      default: false,
    },
    /**
     * Leading controls view
     */
    leading: {
      type: Boolean,
      default: false,
    },
    /**
     * Waiting for stream is published to server
     */
    goOnAirBlocked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      audioSelected: null,
      videoSelected: null,
      resolutionSelected: RESOLUTIONS["240p"],
      serverSelected: null,
      rtmpSelected: null,
      resolutionKeys: {
        key: "name",
        label: "name",
        disabled: "disabled",
      },
      serverKeys: {
        key: "value",
        label: "label",
        disabled: "disabled",
      },
      recordTypeKeys: {
        key: "id",
        label: "label",
        disabled: "disabled",
      },
      expanded: false,

      // Временная блокировка кнопки, потом убрать
      disableOnAirButton: true,
      disableOnAirTimeout: null,
    };
  },
  computed: {
    disableControls() {
      return this.leading ? this.onAir : this.prepared;
    },
    visibleAdditionalOpts() {
      return (this.typicalType || this.one2ManyType) && !this.videoType;
    },
    availableGoOnAir() {
      return (
        (this.videoDevices.every(device => device.deviceId !== "0") &&
          this.audioDevices.every(device => device.deviceId !== "0")) ||
        this.videoType
      );
    },
  },
  watch: {
    async pending(val) {
      if (!val) {
        await this.$nextTick();
        await this.setAllowedSettings();
      }
    },
    resolutions: {
      handler() {
        this.setSettings("resolution", this.getAvailableResolution());
      },
      deep: true,
    },

    servers() {
      this.setSettings(
        "server",
        this.servers.find(s => s.value === this.serverSelected.value),
      );
    },

    // Временная блокировка кнопки, потом убрать
    prepared(val) {
      if (val) {
        this.disableOnAirButton = true;

        this.disableOnAirTimeout = setTimeout(() => {
          this.disableOnAirButton = false;
        }, 10000);
      } else if (this.disableOnAirTimeout) {
        clearTimeout(this.disableOnAirTimeout);
      }
    },
  },
  created() {
    if (!this.pending) {
      this.setAllowedSettings();
    }
  },
  methods: {
    async setAllowedSettings() {
      if (this.audioDevices[0]) {
        this.setSettings("audio", this.audioDevices[0]);
      }
      if (this.videoDevices[0]) {
        this.setSettings("video", this.videoDevices[0]);
      }
      if (this.one2ManyType) {
        const server = this.servers.find(s => s.label.includes("CDN"));
        this.setSettings("server", server ?? this.servers[0]);
      } else {
        this.setSettings("server", this.servers[0]);
      }
      this.setSettings("rtmp", this.rtmpServers[0]);
      this.setSettings("resolution", this.getAvailableResolution());
    },
    getAvailableResolution() {
      const availableResolutions = this.resolutions.filter(res => !res.disabled);
      const resolutionWidthList = availableResolutions.map(res => res.width);
      const maxWidthFromAvailable = Math.max(...resolutionWidthList);
      return availableResolutions.find(res => res.width === maxWidthFromAvailable);
    },
    setSettings(key, value) {
      if (value) {
        this[`${key}Selected`] = value;
        this.$emit(`set-${key}`, value);
      }
    },
    toggle() {
      this.expanded = !this.expanded;
    },

    bufferingChangeHandler(value) {
      if (value && this.screenSaver) {
        this.screenSaverChangeHandler(false);
      }
      this.$emit("set-buffering", value);
    },

    screenSaverChangeHandler(value) {
      this.$emit("set-screen-saver", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.broadcast-controls {
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  height: 100%;
  padding: 15px;

  &--on-air {
    grid-template-rows: 1fr;
  }

  @media (min-width: 1024px) {
    grid-template-rows: 1fr;
  }

  &__button {
    grid-row: 2;
    align-self: flex-end;

    &-icon {
      @media (max-width: 370px) {
        display: none;
      }
    }

    &--full-width {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    @media (pointer: coarse) and (orientation: landscape) and (max-width: 768px) {
      font-size: 14px;
    }
  }

  &--on-air &__button {
    grid-row: 1;
  }

  &__settings {
    position: relative;
    grid-column-start: 1;
    grid-column-end: 3;
    padding: 0 15px;
    margin: 0 -15px;
    overflow: auto;
    border-bottom: solid 1px #f2f2f2;
  }

  &__select {
    width: 100%;
    margin-bottom: 17px;
  }

  &__additional-opt {
    min-height: 15px;
    padding: 0 5px;
    font-size: 15px;
    line-height: 20px;

    @media (min-width: 480px) {
      min-height: 29px;
      padding: 7px 5px;
    }

    &-wrapper {
      padding-top: 30px;
    }
  }

  &__opt-item:not(:last-of-type) {
    margin-bottom: 10px;
  }

  &--leading &__button {
    grid-row: 2;
  }

  &__expander-wrapper {
    position: relative;
    width: 100%;

    &::before {
      position: absolute;
      top: 8px;
      width: 100%;
      height: 1px;
      content: "";
      background-color: $gray-light;
    }
  }

  &__expander {
    position: absolute;
    top: 0;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
    margin: 0 auto;
    color: $gray-darken;
    text-align: center;
    cursor: pointer;
    background-color: $white;
    transform: translateX(-50%);

    &-icon {
      margin-left: 5px;
    }
  }
}
</style>
