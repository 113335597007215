<template>
  <layout-page>
    <div :class="$style.content" class="flex">
      <container-speaker ref="container" />
    </div>
    <system-notifications group="broadcast" position="top right" />
    <system-notifications
      v-fullscreen.attach="'broadcast'"
      group="broadcastWarning"
      position="top center"
      :close-on-click="true"
    />
    <system-notifications group="common" position="top center" />
    <speaker-web-socket-container />
  </layout-page>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { ROLE_SET_MODE } from "@/store/mutations/mutation-types";
import { broadcast, speakerPolls } from "@/store/modules/store.namespaces";
import { GET_ABOUT_WS_QUIZ } from "@/store/modules/speaker/modules/polls/action-types";
import { SpeakerRouteName } from "@/constants/router/router-const";

import SpeakerWebSocketContainer from "@/components/speaker/SpeakerWebSocketContainer";
import SystemNotifications from "@/components/common/notifications/SystemNotifications";
import LayoutPage from "@/components/layouts/LayoutPage";
import ContainerSpeaker from "@/containers/speaker/ContainerSpeaker";

const TIME_TO_DISCONNECT = 150;

export default {
  name: "ViewSpeaker",
  components: {
    ContainerSpeaker,
    SpeakerWebSocketContainer,
    SystemNotifications,
    LayoutPage,
  },
  computed: {
    ...mapState(broadcast, ["onAir", "showWarning"]),
    isRoomsRoute() {
      return this.$route.name === SpeakerRouteName.Rooms;
    },
  },
  watch: {
    isRoomsRoute(val) {
      if (!val) {
        this.initSpeaker();
      }
    },
    showWarning(val) {
      if (val) {
        this.showBroadcastWarningMessage();
      } else {
        this.$notify({
          group: "broadcastWarning",
          clear: true,
        });
      }
    },
  },
  mounted() {
    if (!this.isRoomsRoute) {
      this.initSpeaker();
    }
  },
  async beforeRouteUpdate(to, from, next) {
    if (this.onAir && to.name === SpeakerRouteName.Rooms) {
      try {
        await this.$dialog
          .confirm(
            {
              title: this.$t("broadcast.youStillOnAir"),
              body: this.$t("broadcast.endYourStream"),
            },
            {
              loader: true,
              okText: this.$t("broadcast.stop"),
              cancelText: this.$t("common.cancel"),
            },
          )
          .then(dialog => {
            this.$refs.container.$refs.broadcastPanel.broadcastExitFromOnAir(true);
            dialog.close();
          });
        next();
      } catch (e) {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    ...mapMutations({
      setRoleMode: ROLE_SET_MODE,
    }),
    ...mapActions(speakerPolls, {
      getAboutWSQuiz: GET_ABOUT_WS_QUIZ,
    }),
    initSpeaker() {
      this.setRoleMode("admin");
      this.getAboutWSQuiz();
    },
    /**
     * Показываем предупреждение спикеру если пульс стрима перестал уходить.
     */
    showBroadcastWarningMessage() {
      this.$notify({
        group: "broadcastWarning",
        type: "error",
        title: this.$t("broadcast.attention"),
        duration: -1,
        ignoreDuplicates: true,
        data: {
          timer: {
            value: TIME_TO_DISCONNECT,
            append: true,
            dictKey: "broadcast.streamConnectionWarning",
          },
        },
      });
    },
  },
};
</script>
<style lang="scss" module>
.content {
  grid-area: content;
  overflow: hidden;
}
</style>
