import axios from "axios";
import config from "../settings/config";

export const SLOW_CONNECT_ERROR = "slow_connect";
export const OLD_VERSION_ERROR = "old_version";
export const NOT_SUPPORT_ERROR = "not_support";
export const HIGH_PING = "high_ping";
export const NOT_AVAILABLE_DEVICES = "devices";
export const NOT_AVAILABLE_BROADCAST = "not_available_broadcast";

export default {
  testProvidedUrlAccesbiity: function(url) {
    return axios.get(url);
  },
  getFreeGeoIp: function() {
    return axios.get(
      `//api.ipstack.com/check?access_key=${
        config.FREEGEOIP_KEY
      }&output=json&language='en'`,
    );
  },
  checkSpeed: function() {
    return new Promise(resolve => {
      const MIN_CONNECT_SPEED = 2;

      let responceObject = {
        mbConnection: null,
        checkConnect: null,
      };
      let connectionSpeed = 0;
      let time_end = 0;
      let time_load = 0;
      let images_size = 1722;
      let time_start = 0;
      let xhr = new XMLHttpRequest();
      xhr.open("GET", "data.png?" + new Date().getTime(), true);
      xhr.onreadystatechange = function() {
        // (3)
        if (xhr.readyState === xhr.HEADERS_RECEIVED) {
          time_start = parseInt(new Date().getTime());
        }
        if (xhr.readyState === xhr.DONE) {
          time_end = parseInt(new Date().getTime());
          time_load = time_end - time_start;
          connectionSpeed = parseInt(images_size / (time_load / 1024), 10);
          responceObject.mbConnection =
            (connectionSpeed / 128).toFixed(2) || "0.5";
          responceObject.checkConnect =
            responceObject.mbConnection > MIN_CONNECT_SPEED;
          resolve(responceObject);
        }
      };
      xhr.onerror = function(err) {
        resolve({
          error: err,
        });
      };
      xhr.send();
    });
  },
  systemTestPostRequest: function(sendObject) {
    return axios.post(config.serverUrl + "/web/api/systemTest", sendObject);
  },
};
