<template>
  <div ref="wrapper" :class="$style.wrapper">
    <div v-show="leftArrow" :class="[$style.arrow, $style.back]" @click="scrollLeft"></div>
    <slot />
    <div v-show="rightArrow" :class="[$style.arrow, $style.forward]" @click="scrollRight"></div>
  </div>
</template>

<script>
import { throttle } from "@/utils";

const CHECK_ARROWS_THROTTLE_DELAY = 1000;
const NAV_ITEM_WIDTH = 90;

export default {
  name: "NavigationScrollArrows",
  data() {
    return {
      leftArrow: null,
      rightArrow: null,
      resizeObserver: null,
    };
  },
  computed: {
    throttledCheckArrow() {
      return throttle(this.checkArrows, CHECK_ARROWS_THROTTLE_DELAY);
    },
    navListRefEl() {
      return this.$slots.default[0].elm;
    },
  },
  mounted() {
    this.addCheckArrowsListeners();
    setTimeout(this.checkArrows, 100);
    this.setResizeObserver();
  },
  beforeDestroy() {
    this.removeCheckArrowsListeners();
    if (this.$refs.wrapper && this.resizeObserver) {
      this.resizeObserver.unobserve(this.$refs.wrapper);
    }
  },
  methods: {
    scrollLeft() {
      this.scrollNavList("left");
    },
    scrollRight() {
      this.scrollNavList();
    },
    scrollNavList(to = "right") {
      if (this.navListRefEl) {
        const position =
          to === "left"
            ? this.navListRefEl.scrollLeft - this.navListRefEl.offsetWidth
            : this.navListRefEl.scrollLeft + this.navListRefEl.offsetWidth;
        this.scrollNavListToPosition(position, false);
      }
    },
    addCheckArrowsListeners() {
      this.navListRefEl.addEventListener("scroll", this.throttledCheckArrow);
    },
    removeCheckArrowsListeners() {
      this.navListRefEl.removeEventListener("scroll", this.throttledCheckArrow);
    },
    checkArrows() {
      if (!this.navListRefEl) return;
      const { offsetWidth, scrollWidth, scrollLeft } = this.navListRefEl;
      if (scrollWidth > offsetWidth) {
        this.leftArrow = scrollLeft > 0;
        this.rightArrow = scrollLeft < scrollWidth - offsetWidth;
      } else {
        this.leftArrow = null;
        this.rightArrow = null;
      }
    },
    scrollNavListToPosition(targetPositionByX, setAnchorToCenter) {
      if (this.navListRefEl) {
        let nextPosition = targetPositionByX;
        if (setAnchorToCenter) {
          nextPosition -= NAV_ITEM_WIDTH;
        }
        nextPosition = Math.max(0, Math.min(nextPosition, this.navListRefEl.scrollWidth));

        try {
          this.$scrollTo(this.navListRefEl, { left: nextPosition });
        } catch {
          this.navListRefEl.scrollLeft = nextPosition;
        }
      }
    },
    setResizeObserver() {
      this.resizeObserver = new ResizeObserver(this.throttledCheckArrow);
      this.resizeObserver.observe(this.$refs.wrapper);
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
  position: relative;
  height: $navigation-height;
  overflow: hidden;
}

.arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 32px;
  cursor: pointer;

  &::before {
    position: absolute;
    top: 20px;
    display: block;
    width: 14px;
    height: 14px;
    content: "";
    border: solid 2px #ffffff;
    transform: rotate(45deg);
  }
}

.back {
  left: 0;
  z-index: 10;
  background-image: linear-gradient(to left, rgba($black, 0), rgba($black, 0.2));

  &::before {
    left: 12px;
    border-top: none;
    border-right: none;
  }
}

.forward {
  right: 0;
  background-image: linear-gradient(to right, rgba($black, 0), rgba($black, 0.2));

  &::before {
    right: 12px;
    border-bottom: none;
    border-left: none;
  }
}

@media (min-width: 1366px) {
  .arrow {
    height: $navigation-height;

    &::before {
      top: 20px;
    }
  }
}
</style>
