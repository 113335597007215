<script>
import BaseWebSocketContainer from "@/components/common/web-socket/BaseWebSocketContainer";
import { socketSpeaker } from "@/store/modules/store.namespaces";

export default {
  name: "SpeakerWebSocketContainer",
  extends: BaseWebSocketContainer,
  computed: {
    storeModuleNamespace() {
      return socketSpeaker;
    },
  },
  methods: {
    chooseNamespace() {
      return this.storeModuleNamespace;
    },
  },
};
</script>
