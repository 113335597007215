var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout-navigation',[_c('div',{staticClass:"flex flex-center",class:[
      _vm.$style.beforeNav,
      ( _obj = {}, _obj[_vm.$style.padded] = !_vm.isPresentationRoute, _obj ) ],attrs:{"slot":"before"},slot:"before"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isPresentationRoute),expression:"!isPresentationRoute"}],attrs:{"src":require("@/assets/images/ws-lite.svg"),"alt":"WhenSpeak"}}),(!this.$isMobile)?_c('navigation-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.isPresentationRoute),expression:"isPresentationRoute"}],attrs:{"item":{
        route: 'speaker-presentations',
        icon: 'arrow-left',
        text: _vm.$t('navigation.allPresentations'),
      }}}):_vm._e()],1),_c('navigation',{staticClass:"uk-flex uk-flex-center",class:_vm.$style.navigation,attrs:{"slot":"navigation","items":_vm.navigationItems},slot:"navigation"}),_c('ws-button',{class:_vm.$style.menuBtn,attrs:{"slot":"after","data-testid":"burgerMenuBtn","lg":"","icon":"","color":"default"},on:{"click":function($event){return _vm.$emit('menu-show')}},slot:"after"},[_c('ws-icon',{attrs:{"xxl":"","light":"","color":"white"}},[_vm._v("ellipsis-v")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }