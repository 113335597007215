<template>
  <div></div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { socket } from "@/store/modules/store.namespaces";
import AuthService from "@/services/AuthService";
import config from "@/settings/config";
import { SOCKET_CONNECT } from "@/store/modules/socket/action-types";

const MAX_DELAY = 60000;

export default {
  name: "WebSocket",
  props: {
    room: {
      type: String,
      required: true,
    },
    user: {
      type: Object,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      instance: null,
      reconnection: false,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000,
      reconnectionCount: 0,
      online: true,
      bindedOfflineHandler: false,
      bindedOnlineHandler: null,
      reconnectionTimeout: null,
      reconnectError: false,
      connected: false,
    };
  },
  computed: {
    ...mapState(socket, ["socketObj"]),
    isOpened() {
      return this.instance && this.instance.readyState === WebSocket.OPEN;
    },
  },
  watch: {
    room() {
      this.connected ? this.changeConnection() : this.connect();
    },
    socketObj(obj) {
      if (!this.isOpened) {
        return;
      }
      this.instance.send(JSON.stringify(obj));
    },
  },
  created() {
    this.setOptions();
    this.connect();
    this.addConnectionLostListener();
  },
  beforeDestroy() {
    if (this.reconnectionTimeout) {
      clearTimeout(this.reconnectionTimeout);
    }
    if (this.instance) {
      this.removeConnectionLostListener();
      this.disconnect();
    }
  },
  methods: {
    ...mapActions(socket, {
      socketConnect: SOCKET_CONNECT,
    }),
    setOptions() {
      this.reconnection = this.options.reconnection || false;
      this.reconnectionAttempts = this.options.reconnectionAttempts || Infinity;
      this.reconnectionDelay = this.options.reconnectionDelay || 1000;
    },
    connect() {
      if (!this.room) return;
      this.setupWebSocket();
      this.addSocketListeners();
    },
    setupWebSocket() {
      this.instance = new WebSocket(this.getConnectionUrl());
    },
    getConnectionUrl() {
      let urlPart = "";
      if (this.user) {
        urlPart = `?login=${this.user.login}&fio=${this.user.fio}`;
      }
      return `${config.WEB_SOCKET_SERVER_URL}/${this.room}/${AuthService.getToken()}${urlPart}`;
    },
    addConnectionLostListener() {
      this.bindedOnlineHandler = this.onlineHandler.bind(this);
      this.bindedOfflineHandler = this.offlineHandler.bind(this);
      window.addEventListener("offline", this.bindedOfflineHandler);
      window.addEventListener("online", this.bindedOnlineHandler);
    },
    onlineHandler() {
      if (!this.online) {
        this.online = true;
        if (this.instance) {
          this.disconnect();
        }
        this.reconnect();
      }
    },
    offlineHandler() {
      this.online = false;
    },
    removeConnectionLostListener() {
      window.removeEventListener("offline", this.bindedOfflineHandler);
      window.removeEventListener("online", this.bindedOnlineHandler);
    },
    changeConnection() {
      this.disconnect();
      this.connect();
    },
    disconnect() {
      this.reconnection = false;
      this.connected = false;
      this.removeSocketListeners();
      this.instance.close();
      this.instance = null;
    },
    addSocketListeners() {
      this.instance.addEventListener("error", evt => {
        this.$loggers.$sentry.sendException(new Error("WebSocket error"), {
          extra: {
            event: evt.data,
            url: this.getConnectionUrl(),
          },
          level: this.$sentryLoggerLevels.Critical,
        });
        this.$emit("error", evt.data);
      });
      this.instance.addEventListener("open", () => {
        this.socketConnect(this.reconnectionCount);
        this.connected = true;
        this.$emit("open", this.reconnectionCount);
        if (this.reconnection) {
          this.reconnectionCount = 0;
        }
      });
      this.instance.onclose = () => {
        if (this.reconnection) {
          this.reconnect();
        }
        this.$emit("close");
      };
      this.instance.onmessage = data => {
        this.$emit("message", this.parseMessage(data));
      };
    },
    removeSocketListeners() {
      if (this.instance) {
        this.instance.onerror = null;
        this.instance.onopen = null;
        this.instance.onclose = null;
        this.instance.onmessage = null;
      }
    },
    parseMessage(message) {
      return JSON.parse(decodeURIComponent(encodeURIComponent(message.data)));
    },
    reconnect() {
      if (this.reconnectionCount <= this.reconnectionAttempts) {
        this.reconnectionCount++;
        clearTimeout(this.reconnectTimeoutId);
        this.reconnectionDelay = Math.min(this.reconnectionDelay * 2, MAX_DELAY);
        this.reconnectTimeoutId = setTimeout(() => {
          this.connect(this.getConnectionUrl());
          this.addSocketListeners();
        }, this.reconnectionDelay);
      } else {
        throw new Error("Socket reconnection attempts expired");
      }
    },
  },
};
</script>
