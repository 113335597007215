<template>
  <div class="broadcast-state">
    <div class="flex">
      <broadcast-connection-quality :quality="connectionQuality" />
      <span v-if="onAir && onAirTime" class="broadcast-state__time">
        {{ onAirTime }}
      </span>
    </div>
    <template v-if="!disableCounter">
      <span class="broadcast-state__counter" :title="$t('broadcast.participantsHandUp')">
        <ws-icon
          sm
          class="broadcast-state__counter-icon"
          :class="{
            'broadcast-state__counter-icon--active': handsUpCount,
            'broadcast-state__counter-icon--swing': handsUpCount,
          }"
        >
          hand-paper
        </ws-icon>
        {{ handsUpCount }}
      </span>
      <span class="broadcast-state__counter" :title="$t('broadcast.participantsOnAir')">
        <ws-icon sm class="broadcast-state__counter-icon">broadcast-tower</ws-icon>
        {{ speakersCount }}
      </span>
      <span class="broadcast-state__counter" :title="$t('broadcast.participantsViewers')">
        <ws-icon sm class="broadcast-state__counter-icon">eye</ws-icon>
        {{ usersCount }}
      </span>
    </template>
  </div>
</template>

<script>
import { ConnectionQuality } from "@/constants/broadcast/broadcast-const";
import BroadcastConnectionQuality from "@/components/common/broadcast/BroadcastConnectionQuality";
import WsIcon from "@/components/base/WsIcon";

export default {
  name: "SpeakerBroadcastState",
  components: {
    WsIcon,
    BroadcastConnectionQuality,
  },
  props: {
    /**
     * Stream successfully launched and available to participants.
     */
    onAir: {
      type: Boolean,
      default: false,
    },
    /**
     * The total number of participants (including the speaker himself) speaking live.
     */
    speakersCount: {
      type: Number,
      default: 0,
    },
    /**
     * The total number of participants (not including speakers) for the stream.
     */
    usersCount: {
      type: Number,
      default: 0,
    },
    /**
     * The number of participants wishing to speak live.
     */
    handsUpCount: {
      type: Number,
      default: 0,
    },
    disableCounter: {
      type: Boolean,
      default: false,
    },
    /**
     * Качество соединения стрима
     */
    connectionQuality: {
      type: String,
      default: ConnectionQuality.Off,
    },
  },

  data() {
    return {
      onAirTime: "",
      watchTimer: null,
    };
  },

  watch: {
    onAir(value) {
      if (value) {
        this.startWatchTimer();
      } else {
        this.stopWatchTimer();
      }
    },
  },

  mounted() {
    if (this.onAir) {
      this.startWatchTimer();
    }
  },

  beforeDestroy() {
    this.stopWatchTimer();
  },

  methods: {
    startWatchTimer() {
      this.stopWatchTimer();

      const startTimestamp = new Date().getTime();

      this.watchTimer = setInterval(() => {
        const watchTime = new Date().getTime() - startTimestamp;
        this.onAirTime = this.formatRowTime(watchTime);
      }, 1000);
    },
    stopWatchTimer() {
      if (this.watchTimer) {
        clearInterval(this.watchTimer);
      }
      this.onAirTime = "";
    },
    formatRowTime(time) {
      const seconds = String(Math.floor((time / 1000) % 60)).padStart(2, "0");
      // prettier-ignore
      const minutes = String(Math.floor((time / (1000 * 60)) % 60)).padStart(2, "0");
      const hours = Math.floor((time / (1000 * 60 * 60)) % 24);

      return hours ? `${hours}:${minutes}:${seconds}` : `${minutes}:${seconds}`;
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/styles/_vars";

@keyframes swing {
  0% {
    transform: translateY(0) rotate(0);
  }

  5% {
    transform: translateY(-3px) rotate(0);
  }

  6% {
    transform: translateY(-3px) rotate(7deg);
  }

  9% {
    transform: translateY(-3px) rotate(-7deg);
  }

  12% {
    transform: translateY(-3px) rotate(7deg);
  }

  15% {
    transform: translateY(-3px) rotate(-7deg);
  }

  18% {
    transform: translateY(-3px) rotate(7deg);
  }

  21% {
    transform: translateY(-3px) rotate(-7deg);
  }

  24% {
    transform: translateY(-3px) rotate(7deg);
  }

  27% {
    transform: translateY(-3px) rotate(0);
  }

  32% {
    transform: translateY(0) rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(@broadcast-dot-indicator-active, 0.5);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(@broadcast-dot-indicator-active, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(@broadcast-dot-indicator-active, 0);
  }
}

.broadcast-state {
  display: grid;
  grid-template-columns: 1fr repeat(3, auto);
  align-items: center;
  width: 100%;
  padding: 20px 15px;
  font-size: 15px;
  line-height: 18px;
  user-select: none;
  background-color: @modal-form-background;

  &__time {
    margin-left: 5px;
    color: @broadcast-dot-indicator-active;
  }

  &__counter {
    display: flex;
    align-items: center;
    margin-left: 8px;

    @media (min-width: 370px) {
      margin-left: 16px;
    }

    &-icon {
      margin-right: 7px;
      color: @broadcast-dot-indicator-inactive;

      &--active {
        color: @broadcast-dot-indicator-active;
      }

      &--swing {
        transform-origin: center 120%;
        animation: swing linear 4s infinite;
      }
    }
  }
}
</style>
