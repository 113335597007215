var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"broadcast-participant"},[_c('div',{staticClass:"broadcast-participant__photo",class:{
      'broadcast-participant__photo--speaking': false,
    },style:({
      backgroundImage: _vm.participant.photo ? ("url(" + (_vm.participant.photo) + ")") : null,
    })}),_c('div',{staticClass:"broadcast-participant__name",attrs:{"title":_vm.participant.name}},[_vm._v(" "+_vm._s(_vm.participant.name)+" ")]),_c('ws-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.participant.onAir),expression:"participant.onAir"}],staticClass:"broadcast-participant__icon-btn",attrs:{"icon":""},on:{"click":_vm.toggleControl}},[_c('ws-icon',{attrs:{"light":"","lg":"","color":_vm.participant.user_id === _vm.currentControlUserId ? 'black' : 'default'}},[_vm._v(" "+_vm._s("user-chart")+" ")])],1),(_vm.participant.raiseHand)?_c('ws-button',{staticClass:"broadcast-participant__button",attrs:{"rounded":"","sm":"","color":"primary"},on:{"click":function($event){return _vm.$emit('accept')}}},[_vm._v(" "+_vm._s(_vm.$t("common.accept"))+" ")]):(_vm.participant.onAir || _vm.participant.permissions.canPresent)?[_c('ws-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.participant.onAir),expression:"participant.onAir"}],staticClass:"broadcast-participant__icon-btn",class:{
        'broadcast-participant__icon-btn--muted': !_vm.participant.permissions.audio,
      },attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('toggle-audio')}}},[_c('ws-icon',{attrs:{"light":"","lg":"","color":"black"}},[_vm._v(" "+_vm._s(_vm.iconAudio)+" ")])],1),_c('ws-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.participant.onAir),expression:"participant.onAir"}],staticClass:"broadcast-participant__icon-btn",class:{
        'broadcast-participant__icon-btn--muted': !_vm.participant.permissions.video,
      },attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('toggle-video')}}},[_c('ws-icon',{attrs:{"light":"","lg":"","color":"black"}},[_vm._v(" "+_vm._s(_vm.iconVideo)+" ")])],1),(!_vm.owner)?_c('ws-button',{staticClass:"broadcast-participant__icon-btn broadcast-participant__icon-btn--muted",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('disconnect')}}},[_c('ws-icon',{attrs:{"light":""}},[_vm._v(" user-minus ")])],1):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }