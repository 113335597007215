<template>
  <router-link
    v-show="visible"
    :class="[
      $style.link,
      {
        [$style.active]: isActive,
      },
    ]"
    class="text-xs flex flex-column"
    :to="routeTo"
    @click.native="handleClick"
  >
    <ws-icon light xl :class="$style.icon">{{ item.icon }}</ws-icon>
    <span v-line-clamp="2" :class="$style.text">{{ item.text }}</span>
    <span
      v-if="hasCounter"
      v-show="hasCounter && item.counter !== 0"
      :class="[
        $style.badge,
        {
          [$style.rounded]: item.counter < 10,
        },
      ]"
    >
      {{ item.counter }}
    </span>
  </router-link>
</template>

<script>
import WsIcon from "@/components/base/WsIcon";

/**
 * Элемент навигационного меню
 *
 * @typedef {object} NavItem
 * @property {string} route - маршрут
 * @property {string} icon - имя иконки
 * @property {string} text - подпись элемента навигации
 * @property {number} counter - счетчик
 * @property {Function} activeFunc - функция определяющая активный ли элемент
 * @property {Array} mq - список media query ключей при которых элемент отображен
 * @property {Function} callback - дополнительная функция которую нужно вызвать при клике на элемент
 * @vue-prop {NavItem} item - элемент навигации
 * @vue-computed {boolean} isActive - активный ли элемент
 * @vue-computed {boolean} visible - видим ли элемент
 * @vue-computed {boolean} hasCount - есть ли счетчик
 */
export default {
  name: "NavigationLink",
  components: {
    WsIcon,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isActive() {
      let activeByParams = true;
      if (this.item.routeParams) {
        activeByParams = Object.entries(this.item.routeParams).every(([key, value]) => {
          return this.$route.params && this.$route.params[key] === value;
        });
      }

      return (
        (this.item.activeFunc && this.item.activeFunc(this.$route.name)) ??
        (this.$route.name === this.item.route && activeByParams)
      );
    },
    visible() {
      return this.item.mq?.includes(this.$mq) ?? true;
    },
    hasCounter() {
      return "counter" in this.item && this.item.counter !== null;
    },
    routeTo() {
      const params =
        this.item.route && this.item.routeParams ? { params: this.item.routeParams } : {};
      return (this.item.route && { name: this.item.route, ...params }) || "";
    },
  },
  methods: {
    /**
     * Обработчик клика на элемент навигации для вызова дополнительной функции
     */
    handleClick() {
      if (this.item.callback) {
        this.item.callback();
      }
    },
  },
};
</script>

<style lang="scss" module>
.link {
  position: relative;
  align-items: center;
  min-width: 90px;
  max-width: 110px;
  min-height: 60px;
  padding: 10px 5px 0;
  color: var(--base-button-active-color);
  text-align: center;
  transition: background linear 0.3s;
}

.link:hover,
.active {
  background: linear-gradient(180deg, var(--base-color) 0%, rgba($white, 0.2) 100%);
  color: #fff;
  text-decoration: none;
}

.icon {
  margin-bottom: 7px;
  opacity: 0.4;
}

.active .icon,
.link:hover .icon {
  opacity: 1;
}

.badge {
  position: absolute;
  top: 8px;
  left: 53%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 17px;
  padding: 5px;
  background-color: var(--base-color_drkn);
  border-radius: 25px;
}

.rounded {
  width: 17px;
  border-radius: 50%;
}

.text {
  word-break: break-word !important;
}
</style>
