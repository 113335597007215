<template>
  <web-socket
    v-bind="socketProps"
    @message="messageHandler"
    @error="errorHandler"
    @open="openHandler"
    @close="closeHandler"
  />
</template>
<script>
import { mapState } from "vuex";
import snakeCase from "lodash/snakeCase";
import { room } from "@/store/modules/store.namespaces";
import WebSocket from "./WebSocket";

export default {
  name: "BaseWebSocketContainer",
  components: {
    WebSocket,
  },
  computed: {
    ...mapState(room, ["roomNumber"]),
    defaultSocketProps() {
      return {
        options: {
          reconnection: true,
        },
        room: this.roomNumber || "",
      };
    },
    socketProps() {
      return this.defaultSocketProps;
    },
    storeModuleNamespace() {
      return null;
    },
  },
  methods: {
    messageHandler(message) {
      if (!this.storeModuleNamespace) return;
      const normalizedActionType = snakeCase(message.type).toUpperCase();
      const moduleNamespace = this.chooseNamespace(normalizedActionType);
      if (!moduleNamespace) return;
      const actionType = this.makeActionType(moduleNamespace, normalizedActionType);
      if (!this.$store._actions[actionType]) return;
      this.$store.dispatch(actionType, message);
    },
    errorHandler() {},
    openHandler() {},
    closeHandler() {},
    makeActionType(moduleNamespace, type) {
      return `${moduleNamespace}/${type}`;
    },
    chooseNamespace() {
      return this.storeModuleNamespace;
    },
  },
};
</script>
