<template>
  <smart-modal
    type="form"
    class="speaker-broadcast-join-modal"
    :no-blocking="desktop"
    :text-cancel="$t('common.hide')"
    :text-confim="$t('common.accept')"
    :class="{ fullscreen: $fullscreen.isFullscreen && $fullscreen.group === 'broadcast' }"
    @close="closeModal"
    @cancel="closeModal"
    @confirm="handleRequester"
  >
    <template #body>
      <div class="speaker-broadcast-join">
        <div class="speaker-broadcast-join__nav">
          <ws-button
            fab
            color="primary"
            outlined
            :disabled="!allowPrev"
            @click="currentRequesterIndex -= 1"
          >
            <ws-icon lg light>arrow-left</ws-icon>
          </ws-button>
          <div class="speaker-broadcast-join__nav-icon" />
          <ws-button
            fab
            color="primary"
            outlined
            :disabled="!allowNext"
            @click="currentRequesterIndex += 1"
          >
            <ws-icon lg light>arrow-right</ws-icon>
          </ws-button>
        </div>

        <div class="speaker-broadcast-join__title">
          <span class="speaker-broadcast-join__name" :title="currentRequesterName">
            {{ currentRequesterName }}
          </span>
          {{ $t("broadcast.readyToJoin") }}
        </div>

        <div class="speaker-broadcast-join__desc">
          {{ $t("broadcast.joinRequestDescription") }}
        </div>
      </div>
    </template>
  </smart-modal>
</template>

<script>
import SmartModal from "@/components/common/modals/SmartModal";
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";

export default {
  name: "SpeakerBroadcastJoinOnAirRequests",
  components: {
    SmartModal,
    WsButton,
    WsIcon,
  },
  props: {
    /**
     * Participants requesting join to air
     *
     * @typedef {object[]} requester          - requester item
     * @param   {string}   requesters[].user_id    - requester identificator
     * @param   {string}   requesters[].name  - requester name
     */
    requesters: {
      type: Array,
      default: () => [],
    },
    /**
     * Desktop display mode
     */
    desktop: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentRequesterIndex: 0,
    };
  },
  computed: {
    currentRequesterName() {
      return this.requesters[this.currentRequesterIndex]?.name ?? "...";
    },
    allowPrev() {
      return this.requesters.length > 1 && this.currentRequesterIndex;
    },
    allowNext() {
      return this.requesters.length > 1 && this.currentRequesterIndex < this.requesters.length - 1;
    },
  },
  watch: {
    requesters(val) {
      if (val.length) {
        if (this.currentRequesterIndex < 0) {
          this.currentRequesterIndex = 0;
        }
        return;
      }
      this.closeModal();
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    handleRequester() {
      const { user_id } = this.requesters[this.currentRequesterIndex];
      if (this.currentRequesterIndex === this.requesters.length - 1) {
        this.currentRequesterIndex -= 1;
      }
      this.$emit("confirm", user_id);
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/styles/_vars";

.speaker-broadcast-join {
  display: grid;
  grid-template-areas:
    "nav"
    "."
    "title"
    "."
    "desc";
  grid-template-rows: auto 40px auto 10px auto;
  grid-template-columns: 1fr;
  margin-bottom: 30px;

  &__nav {
    display: grid;
    grid-area: nav;
    grid-auto-flow: column;
    grid-gap: 15px;
    align-items: center;
    justify-content: center;

    &-icon {
      width: 96px;
      height: 96px;
      background-color: @text-light-gray;
      background-image: url("~@/assets/images/hand-up.png");
      background-repeat: no-repeat;
      background-position: 50%;
      border-radius: 50%;
    }
  }

  &__title {
    grid-area: title;
    overflow: hidden;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    color: @black;
    text-align: center;
  }

  &__name {
    display: block;
    overflow: hidden;
    color: @broadcast-link-accent-color;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__desc {
    grid-area: desc;
    font-size: 15px;
    line-height: 18px;
    color: @text-gray;
    text-align: center;
  }
}

.fullscreen {
  z-index: 101;
}

.speaker-broadcast-join-modal {
  max-width: 375px;
}
</style>
