<template>
  <div
    class="speaker-container"
    :class="{
      'speaker-container--has-sidebar': $mqLaptop && !isRoomsRoute,
      'speaker-container--has-sheet': !$mqLaptop && !isRoomsRoute,
    }"
  >
    <speaker-header class="speaker-container__navigation" @menu-show="toggleBurgerMenu" />

    <div class="speaker-container__content">
      <router-view class="speaker-container__content-view" />
    </div>

    <container-broadcast-speaker-panel v-if="!isRoomsRoute" ref="broadcastPanel" />
    <container-speaker-side-bar />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { speaker, auth } from "@/store/modules/store.namespaces";
import { TOGGLE_BURGER_MENU } from "@/store/modules/speaker/mutation-types";
import ContainerSpeakerSideBar from "@/containers/speaker/ContainerSpeakerSideBar";
import ContainerBroadcastSpeakerPanel from "@/containers/broadcast/ContainerBroadcastSpeakerPanel";
import SpeakerHeader from "@/components/speaker/navigation/SpeakerHeader";
import { SpeakerRouteName } from "@/constants/router/router-const";

export default {
  name: "ContainerSpeaker",
  components: {
    ContainerBroadcastSpeakerPanel,
    ContainerSpeakerSideBar,
    SpeakerHeader,
  },
  computed: {
    ...mapState(auth, ["user"]),
    isRoomsRoute() {
      return this.$route.name === SpeakerRouteName.Rooms;
    },
  },
  watch: {
    isRoomsRoute(val) {
      if (!val) {
        this.init();
      }
    },
  },
  mounted() {
    if (!this.isRoomsRoute) {
      this.init();
    }
  },
  beforeDestroy() {
    if (!this.isRoomsRoute) {
      this.$loggers.$sentry.clear();
    }
  },
  methods: {
    ...mapMutations(speaker, {
      toggleBurgerMenu: TOGGLE_BURGER_MENU,
    }),
    init() {
      this.$loggers.$sentry.setUser(this.user);
      this.$loggers.$sentry.setTags({
        room: this.$route.params.id,
        context: "Speaker",
      });
      this.$loggers.$sentry.sendMessage(`Вход в комнату ${this.$route.params.id}`, {
        level: this.$loggers.$sentry.levels.Info,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.speaker-container {
  display: grid;
  grid-template-areas:
    "navigation"
    "content";
  grid-template-rows: max-content 1fr;
  grid-template-columns: minmax(320px, 1fr);
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;

  &--has-sidebar {
    grid-template-areas:
      "navigation navigation"
      "content    sidebar";
    grid-template-rows: max-content 1fr;
    grid-template-columns: 1fr max-content;
  }

  &--has-sheet {
    grid-template-areas:
      "navigation"
      "content"
      "sheet";
    grid-template-rows: max-content 1fr max-content;
  }

  &__navigation {
    grid-area: navigation;
    z-index: 2;
  }

  &__content {
    position: relative;
    grid-area: content;
    overflow: hidden;
  }

  &__content-view {
    width: 100%;
    height: 100%;
    overflow: auto;

    @include reset-scroll;
  }
}
</style>
