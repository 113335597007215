<template>
  <div class="speaker-broadcast-checkbox">
    <div class="speaker-broadcast-checkbox__wrapper">
      <checkbox
        class="speaker-broadcast-checkbox__box"
        :checked="checked"
        :disabled="disabled"
        @input="onCheckedHandler"
      >
        <span class="speaker-broadcast-checkbox__text">{{ label }}</span>
        <span class="speaker-broadcast-checkbox__comment">
          {{ hint }}
        </span>
      </checkbox>
    </div>
  </div>
</template>

<script>
import { Checkbox } from "vue-checkbox-radio";

export default {
  name: "SpeakerBroadcastCheckbox",
  components: {
    Checkbox,
  },
  model: {
    prop: "checked",
    event: "input",
  },
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    hint: {
      type: String,
      default: "",
    },
  },
  methods: {
    onCheckedHandler(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style scoped lang="scss">
.speaker-broadcast-checkbox {
  &__wrapper {
    flex: 1 1 auto;
  }

  &__box ::v-deep label {
    display: grid;
    grid-template-areas:
      "box text"
      "box comment";
    grid-template-columns: auto 1fr;
    gap: 0 15px;
  }

  &__box {
    padding-top: 8px;

    ::v-deep .input-box {
      grid-area: box;
      grid-row: 1 / span 3;
      align-self: center;
      width: 20px;
      height: 20px;
      vertical-align: top;
      border-color: $gray-lighten;
      border-radius: 5px;

      /* stylelint-disable */
      .input-box-tick > path {
        stroke: $white;
        stroke-width: 1.6px;
        stroke-dasharray: 16;
      }
      /* stylelint-enable */
    }

    ::v-deep input:checked + label > .input-box {
      background-color: var(--base-color);
    }
  }

  &__text {
    grid-area: text;
    font-size: 14px;
    line-height: 17px;
  }

  &__comment {
    grid-area: comment;
    font-size: 10px;
    line-height: 12px;
    color: #acacac;
  }
}
</style>
