<script>
import { ConnectionQuality } from "@/constants/broadcast/broadcast-const";

import(/* webpackChunkName: "webrtcAdapter" */ "webrtc-adapter");

export default {
  data() {
    return {
      $FP: null,
      SESSION_STATUS: null,
      STREAM_STATUS: null,
      ROOM_EVENT: null,
      CONNECTION_QUALITY: null,
      connectionQuality: ConnectionQuality.Off,
      logsEnabled: process.env.NODE_ENV === "production",
    };
  },
  methods: {
    async initFlashphoner(speaker = false) {
      if (!window.Flashphoner) {
        const fp = await this.importFlashphoner();
        this.$FP = fp;
        window.Flashphoner = fp;
      } else {
        this.$FP = window.Flashphoner;
      }
      try {
        const options = {
          flashMediaProviderSwfLocation: "./static/js/media-provider.swf",
          receiverLocation: "./static/js/WSReceiver2.js",
          decoderLocation: "./static/js/video-worker2.js",
        };

        if (speaker) {
          options.logger = { push: true };
        }
        this.$FP.init(options);
        this.SESSION_STATUS = this.$FP.constants.SESSION_STATUS;
        this.STREAM_STATUS = this.$FP.constants.STREAM_STATUS;
        this.ROOM_EVENT = this.$FP.roomApi.events;
        this.CONNECTION_QUALITY = this.$FP.constants.CONNECTION_QUALITY;
      } catch (e) {
        console.error("Your browser doesn't support Flash or WebRTC technology");
      }
    },
    async importFlashphoner() {
      return import(/* webpackChunkName: "flashphoner" */ "@/lib/flashphoner/flashphoner");
    },
    /**
     * Логирует полезную информацию в сентри
     * @param {string|Error} message
     * @param {string|Object} [options] - настройки для логирования, либо уровень на котором отобразить сообщение
     */
    log(message, options = {}) {
      if (!this.logsEnabled) return;
      if (message instanceof Error) {
        this.$loggers.$sentry.sendException(message, options);
      } else {
        this.$loggers.$sentry.sendMessage(message, options);
      }
    },
    connectionQualityUpdateHandler(stream) {
      stream.on(this.CONNECTION_QUALITY.UPDATE, quality => {
        this.connectionQuality = quality;
        this.$emit("connection-quality-update", this.connectionQuality);
      });
    },
  },
};
</script>
