<script>
import { mapMutations, mapState } from "vuex";
import { broadcast, systemTest } from "@/store/modules/store.namespaces";
import { SET_TEST_ERRORS } from "@/store/modules/systemTest/mutation-types";
import {
  MEDIA_ERROR,
  FACING_MODE,
  RESOLUTIONS,
  OPTION_EXTERNAL_STREAM,
} from "@/constants/broadcast/broadcast-const";
import { NOT_AVAILABLE_BROADCAST, NOT_AVAILABLE_DEVICES } from "@/services/TestUserSystemService";
import { BROADCAST_SET_STATE_PROP } from "@/store/modules/broadcast/mutation-types";
import { askCameraPermissions } from "@/utils/cordova";

import(/* webpackChunkName="webrtcAdapter" */ "webrtc-adapter");

export default {
  name: "ContainerBroadcastSettings",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    conferenceType: {
      type: Boolean,
      default: false,
    },
    leading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: {
        type: null,
        video: null,
        audio: null,
      },
      pending: false,
      supportIsChecked: false,
      videoDevices: [
        {
          deviceId: "1",
          label: this.$t("broadcast.devicesSearchForAvailable"),
        },
      ],
      audioDevices: [
        {
          deviceId: "1",
          label: this.$t("broadcast.devicesSearchForAvailable"),
        },
      ],
      resolutions: [],
      defaultVideoDevice: {
        deviceId: OPTION_EXTERNAL_STREAM,
        label: this.$t("broadcast.externalStream"),
      },
      stream: null,
    };
  },
  computed: {
    ...mapState(systemTest, ["testErrors"]),
    settings() {
      let { videoDevices } = this;
      if (
        (!this.conferenceType && this.error.type && this.error.video && this.error.audio) ||
        this.disabled
      ) {
        videoDevices = [this.defaultVideoDevice];
      } else if (!this.conferenceType && !this.disabled) {
        videoDevices = [...videoDevices, this.defaultVideoDevice];
      }
      if (this.conferenceType && this.leading) {
        videoDevices = [...videoDevices];
      }
      return {
        audioDevices: this.audioDevices,
        videoDevices,
        resolutions: this.resolutions,
        pending: this.pending,
        error: this.error,
      };
    },
    boundUpdateDevices() {
      return this.updateDevices.bind(this);
    },
    notAvailableDevice() {
      return [{ deviceId: "0", label: this.$t("broadcast.devicesNotAvailable") }];
    },
  },
  async created() {
    if (this.disabled) return;
    await this.init();
  },
  beforeDestroy() {
    if (this.stream) {
      this.stream.getTracks().forEach(track => {
        track.stop();
      });
      this.stream = null;
    }
    this.removeCheckMediaDevicesListener();
  },
  methods: {
    ...mapMutations(broadcast, {
      setBroadcastStateProp: BROADCAST_SET_STATE_PROP,
    }),
    ...mapMutations(systemTest, {
      setTestErrors: SET_TEST_ERRORS,
    }),
    async init() {
      if (this.disabled) return;
      this.pending = true;
      if (window.cordova && window.device.platform === "Android") {
        await askCameraPermissions();
      }
      this.addCheckMediaDevicesListener();
      this.initResolutions();
      await this.initDevices();
      this.$nextTick(this.checkSupportStream);
    },
    async initDevices() {
      const devices = await this.getMediaDevices();

      if (devices) {
        this.fillDevices(devices);
      } else {
        this.error.type = MEDIA_ERROR.NOT_AVAILABLE_DEVICES;
        this.setNotAvailableDevicesError();
      }
    },
    initResolutions() {
      this.resolutions = Object.values(RESOLUTIONS);
      // sort resolutions ASC
      this.resolutions.sort((a, b) => {
        return a.width === b.width ? 0 : a.width > b.width ? 1 : -1;
      });
    },
    fillDevices({ audio, video }) {
      this.audioDevices = audio.length ? audio : this.notAvailableDevice;
      this.videoDevices = video.length ? video : this.notAvailableDevice;
      this.setBroadcastStateProp({
        devices: {
          audio: this.audioDevices,
          video: this.videoDevices,
        },
      });

      this.pending = false;
    },
    async checkAvailableAudio(audioInput) {
      try {
        await this.callUserPermissionsDropdown({ video: false, audio: audioInput });
        if (!this.error.video) {
          this.resetError();
        } else {
          this.error.audio = null;
        }
      } catch {
        this.audioDevices = this.audioDevices.filter(
          device => device.deviceId !== audioInput.deviceId,
        );
        this.showWarnNotify({
          text: this.$t("broadcast.errors.deviceNotFound"),
        });
        this.error.type = MEDIA_ERROR.DEVICE_NOT_FOUND;
        this.error.audio = MEDIA_ERROR.AUDIO_DEVICE_NOT_FOUND;
      }
    },
    async checkAvailableVideo(videoInput) {
      try {
        await this.callUserPermissionsDropdown({ video: videoInput, audio: false });
        if (!this.error.audio) {
          this.resetError();
        } else {
          this.error.video = null;
        }
      } catch {
        this.videoDevices = this.videoDevices.filter(
          device => device.deviceId !== videoInput.deviceId,
        );
        this.showWarnNotify({
          text: this.$t("broadcast.errors.deviceNotFound"),
        });
        this.error.type = MEDIA_ERROR.DEVICE_NOT_FOUND;
        this.error.video = MEDIA_ERROR.VIDEO_DEVICE_NOT_FOUND;
        throw MEDIA_ERROR.VIDEO_DEVICE_NOT_FOUND;
      }
    },
    async checkAvailableResolution(videoInput) {
      try {
        await this.checkAvailableVideo(videoInput);
        this.resolutions = await Promise.all(
          this.resolutions.map(async resolution => {
            try {
              const videoRectKey = this.$isSafariWebRTC ? "ideal" : "min";
              const trackByDeviceId = this.stream.getVideoTracks().find(track => {
                const { deviceId } = track.getSettings();
                return deviceId === videoInput.deviceId;
              });
              if (trackByDeviceId) {
                await trackByDeviceId.applyConstraints({
                  width: { [videoRectKey]: resolution.width },
                  height: { [videoRectKey]: resolution.height },
                });
              }
              return resolution;
            } catch (e) {
              if (e.name === "OverconstrainedError") {
                resolution.disabled = true;
                return resolution;
              }
              return resolution;
            }
          }),
        );
      } catch (e) {
        console.error(e);
      }
    },
    async getMediaDevices() {
      try {
        let devices = await this.tryGetDevices();
        devices = devices.reduce(this.prepareDevices, {
          audio: [],
          video: [],
        });
        this.resetError();
        return devices;
      } catch (reason) {
        this.handleTryGetDevicesErrors(reason);
        return {
          audio: [],
          video: [],
        };
      }
    },
    prepareDevices(accum, device) {
      if (device.kind === "audioinput") {
        accum.audio.push({
          label: device.label || `Microphone 0${accum.audio.length + 1}`,
          deviceId: device.deviceId,
        });
      }
      if (device.kind === "videoinput") {
        const element = {
          label: device.label || `Video camera 0${accum.video.length + 1}`,
          deviceId: device.deviceId,
        };
        if (!this.$isDesktop) {
          element.facingMode =
            device.facingMode === FACING_MODE.environment ||
            /facing back/.test(device.label.toLowerCase()) ||
            /задней панели/.test(device.label.toLowerCase()) ||
            /back camera/.test(device.label.toLowerCase())
              ? FACING_MODE.environment
              : FACING_MODE.user;
        }
        accum.video.push(element);
      }
      return accum;
    },
    async tryGetDevices() {
      if (this.$isIOS && (!this.$hasWebRTC || this.$isIOSNotSupportWebRTC) && !this.$isSafari) {
        throw {
          name: MEDIA_ERROR.UNSUPPORTED_BROWSER_IOS,
        };
      }

      if (!this.$hasWebRTC) {
        throw {
          name: MEDIA_ERROR.UNSUPPORTED_BROWSER,
        };
      }

      await this.callUserPermissionsDropdown({ video: true, audio: true });
      return navigator.mediaDevices.enumerateDevices();
    },
    async callUserPermissionsDropdown(constraints) {
      this.stream = await navigator.mediaDevices.getUserMedia(constraints);
    },
    handleTryGetDevicesErrors(reason) {
      if (
        reason.name === MEDIA_ERROR.UNSUPPORTED_BROWSER_IOS ||
        reason.name === MEDIA_ERROR.UNSUPPORTED_BROWSER
      ) {
        this.error.type = reason.name;
        if (!this.testErrors.includes(NOT_AVAILABLE_BROADCAST)) {
          this.setTestErrors([...this.testErrors, NOT_AVAILABLE_BROADCAST]);
        }
      }
      if (reason.message === MEDIA_ERROR.FAILED_ALLOCATE) {
        this.error.type = reason.message;
        this.setNotAvailableDevicesError();
      }
      if (
        reason.name === MEDIA_ERROR.NOT_ALLOWED_ERROR ||
        reason.message === MEDIA_ERROR.INVALID_CONSTRAINT
      ) {
        this.error.type =
          reason.name === MEDIA_ERROR.NOT_ALLOWED_ERROR ? reason.name : reason.message;
        this.setNotAvailableDevicesError();
      }
    },
    setNotAvailableDevicesError() {
      if (!this.testErrors.includes(NOT_AVAILABLE_DEVICES)) {
        this.setTestErrors([...this.testErrors, NOT_AVAILABLE_DEVICES]);
      }
      this.videoDevices = this.notAvailableDevice;
      this.audioDevices = this.notAvailableDevice;
      this.pending = false;
    },
    addCheckMediaDevicesListener() {
      if (navigator.mediaDevices?.addEventListener) {
        navigator.mediaDevices?.addEventListener("devicechange", this.boundUpdateDevices);
      }
    },
    removeCheckMediaDevicesListener() {
      if (navigator.mediaDevices?.removeEventListener) {
        navigator.mediaDevices?.removeEventListener("devicechange", this.boundUpdateDevices);
      }
    },
    async updateDevices() {
      await this.initDevices();
      this.resetError();
    },
    resetError() {
      this.error = {
        type: null,
        audio: null,
        video: null,
      };
    },
    checkSupportStream() {
      if (!this.$isNotSupportStream || this.supportIsChecked) return;
      this.supportIsChecked = true;
      this.showWarnNotify({
        duration: 60000,
        text: this.$t("broadcast.errors.streamNotSupport"),
      });
    },
    showWarnNotify(options) {
      this.$notify({
        group: "broadcast",
        type: "warn",
        duration: 2000,
        title: this.$t("systemTest.warning"),
        ...options,
      });
    },
  },
  render() {
    return this.$scopedSlots.default(this.settings);
  },
};
</script>
