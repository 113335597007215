// eslint-disable-next-line import/prefer-default-export
export function askCameraPermissions() {
  return new Promise(async (resolve, reject) => {
    const { permissions } = window.cordova.plugins;
    const permList = [permissions.CAMERA, permissions.RECORD_AUDIO];
    const checkPermissionsResult = await Promise.all(
      permList.map(async perm => {
        return new Promise(resolveInner => {
          permissions.checkPermission(
            perm,
            status => {
              resolveInner(status.hasPermission);
            },
            () => resolveInner(false),
          );
        });
      }),
    );
    const hasPermissions = checkPermissionsResult.every(result => result);

    function error() {
      reject(new Error("Permissions are'n granted"));
    }

    function success(status) {
      if (!status.hasPermission) {
        error();
      } else {
        resolve("Permissions are granted");
      }
    }

    if (!hasPermissions) {
      permissions.requestPermissions(permList, success, error);
    } else {
      resolve(true);
    }
  });
}
