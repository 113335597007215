<template>
  <div
    class="broadcast-panel"
    :class="{
      'broadcast-panel--sidebar': sidebar,
      'broadcast-panel--sheet': sheetMode,
      'broadcast-panel--popup': popup,
      'broadcast-panel--onair': onAir,
      'broadcast-panel--has-speakers': $scopedSlots.speakers,
      'broadcast-panel--expanded': innerExpanded,
      'broadcast-panel--full-height': $scopedSlots.popup,
      'broadcast-panel--fullscreen': inFullscreenMode,
      'broadcast-panel--fullscreen-mobile': $mqToLaptop && inFullscreenMode,
    }"
    @touchmove="handleTouchMove"
  >
    <ws-button
      v-if="sidebar"
      class="broadcast-panel__expander"
      color="default"
      icon
      @click="innerExpanded = !innerExpanded"
    >
      <ws-icon md light>{{ innerExpanded ? "chevron-right" : "chevron-left" }}</ws-icon>
    </ws-button>

    <transition name="overlayFade">
      <div
        v-if="sheetMode || popup"
        v-show="innerExpanded"
        v-touch:tap="collapse"
        class="broadcast-panel__overlay"
      />
    </transition>

    <transition name="overlayFade">
      <div
        v-if="$scopedSlots.popup"
        class="broadcast-panel__popup"
        :style="{ maxHeight: panelMaxHeight }"
      >
        <slot name="popup" />
      </div>
    </transition>

    <div
      ref="panel"
      class="broadcast-panel__container"
      :style="!popup && { maxHeight: panelMaxHeight }"
    >
      <div
        v-touch:swipe.bottom="touchHandlersDisabled ? null : collapse"
        v-touch:swipe.top="touchHandlersDisabled ? null : expand"
        v-touch:tap="touchHandlersDisabled ? null : toggle"
        v-touch:moving="touchmoveHandler"
        v-touch:end="touchEndHandler"
        v-touch:start="touchStartHandler"
        class="broadcast-panel__container-header"
        @touchmove="preventTouch"
      >
        <slot name="header" />
      </div>

      <div v-show="loading" class="broadcast-panel__loading">
        <loader center />
      </div>

      <div v-show="!loading" class="broadcast-panel__container-player">
        <div class="broadcast-panel__player">
          <div
            class="broadcast-panel__player-container"
            :data-text="$t('broadcast.noBroadcast')"
            :class="{
              'broadcast-panel__player-container--empty': !this.$slots.player,
            }"
          >
            <slot name="player" />
          </div>
        </div>
      </div>

      <div v-show="!loading" class="broadcast-panel__container-controls">
        <slot name="controls" />
      </div>

      <div v-if="$scopedSlots.info" v-show="!loading" class="broadcast-panel__container-info">
        <slot name="info" />
      </div>

      <div
        v-if="$scopedSlots.speakers"
        v-show="onAir && !loading"
        class="broadcast-panel__container-speakers"
      >
        <slot name="speakers" />
      </div>
    </div>
    <slot />
    <div ref="shadowBorder" class="broadcast-panel__shadow-border" />
  </div>
</template>

<script>
import WsIcon from "@/components/base/WsIcon";
import WsButton from "@/components/base/WsButton";
import Loader from "@/components/common/elements/Loader";

const PAGE_NAV_HEIGHT = 60;
const SHEET_HEADER_HEIGHT = 58;

export default {
  name: "BroadcastPanel",
  components: {
    Loader,
    WsButton,
    WsIcon,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Display the panel as a sidebar (otherwise as a sheet).
     */
    sidebar: {
      type: Boolean,
      default: false,
    },
    popup: {
      type: Boolean,
      default: false,
    },
    /**
     * Stream successfully launched
     */
    onAir: {
      type: Boolean,
      default: false,
    },
    /**
     * Is the panel expanded?
     */
    expanded: {
      type: Boolean,
      default: false,
    },
    expandBlocked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      innerExpanded: this.expanded,
      panelMaxHeight: null,
      windowResizeTimer: 0,
      sheetHeight: 0,
      initialSheetClientY: 0,
      shiftY: 0,
      isAnimationActive: false,
    };
  },
  computed: {
    sheetMode() {
      return !this.sidebar && !this.popup;
    },
    touchHandlersDisabled() {
      return this.expandBlocked || !this.sheetMode;
    },
    inFullscreenMode() {
      return this.$fullscreen.isFullscreen && this.$fullscreen.group === "broadcast";
    },
  },
  watch: {
    expanded(newVal) {
      if (newVal === this.innerExpanded || this.waitAnimation) return;
      newVal ? this.expand() : this.collapse();
    },
  },

  mounted() {
    window.addEventListener("resize", this.handleWindowResize, {
      passive: true,
    });
    this.handleWindowResize();
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.handleWindowResize);
  },

  methods: {
    handleTouchMove(e) {
      if (this.inFullscreenMode || this.innerExpanded) return;
      e.preventDefault();
    },
    handleWindowResize() {
      this.windowResizeTimer = setTimeout(() => {
        if (this.$refs.shadowBorder && this.$refs.shadowBorder.clientHeight !== 0) {
          this.panelMaxHeight = `${this.$refs.shadowBorder.clientHeight}px`;
        } else {
          this.handleWindowResize();
        }
      }, 150);
    },
    toggle() {
      if (this.waitAnimation) return;
      this.innerExpanded ? this.collapse() : this.expand();
      this.waitAnimation = true;
      setTimeout(() => {
        this.waitAnimation = false;
      }, 500);
    },
    collapse() {
      if (this.innerExpanded && !this.waitAnimation) {
        this.innerExpanded = false;
        this.$emit("closing");
        setTimeout(() => {
          this.$emit("closed");
        }, 400);
      }
    },
    expand() {
      if (this.expandBlocked) return;
      if (!this.innerExpanded && !this.waitAnimation) {
        this.innerExpanded = true;
        this.$emit("opening");
        setTimeout(() => {
          this.$emit("opened");
        }, 400);
      }
    },
    preventTouch(e) {
      if (this.isAnimationActive) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    touchStartHandler() {
      if (this.touchHandlersDisabled) return;
      this.isAnimationActive = true;
      this.$refs.panel.style.transition = "transform 0.1s linear";
    },
    touchEndHandler() {
      if (this.touchHandlersDisabled) return;
      this.isAnimationActive = false;
      this.toggle();
      this.initialSheetClientY = 0;
      this.shiftY = 0;
      this.$refs.panel.style.removeProperty("transform");
      this.$refs.panel.style.removeProperty("transition");
    },
    touchmoveHandler(event) {
      if (this.touchHandlersDisabled) return;

      window.requestAnimationFrame(this.resize.bind(this, event));
    },
    resize(event) {
      const elementHeight = this.$refs.panel.getBoundingClientRect().height;
      const maxY = elementHeight - SHEET_HEADER_HEIGHT;
      if (!event.touches[0]) return;
      const { clientY } = event.touches[0];
      if (!this.isAnimationActive) return;

      if (clientY < PAGE_NAV_HEIGHT || clientY > document.body.clientHeight) return;

      if (!this.initialSheetClientY) {
        this.initialSheetClientY = clientY;
      }
      const difference = this.innerExpanded
        ? document.body.clientHeight - clientY
        : clientY - this.initialSheetClientY;
      this.shiftY = -Math.min(Math.max(0, Math.abs(difference)), maxY);
      this.$refs.panel.style.transform = `translateY(${this.shiftY}px)`;
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/styles/_vars.less";

@touch-landscape: ~"(pointer: coarse) and (orientation: landscape)";

.container {
  grid-template-areas:
    "header"
    "player"
    "controls";
  grid-template-rows: var(--sheet-header-height) auto 1fr;
  max-height: ~"calc(100vh - var(--page-nav-height))";

  @media (orientation: landscape) {
    grid-template-areas:
      "header header"
      "player controls";
    grid-template-rows: var(--sheet-header-height) ~"calc(100% - var(--sheet-header-height))";
    grid-template-columns: 300px 1fr;
  }

  @media (orientation: landscape) and (min-width: 570px) {
    grid-template-columns: minmax(300px, 400px) minmax(320px, 1fr);
  }

  @media (orientation: landscape) and (min-width: 768px) {
    grid-template-columns: minmax(320px, 650px) minmax(320px, 1fr);
  }
}

.overlayFade {
  &-enter-active,
  &-leave-active {
    transition: opacity 0.3s;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
  }
}

.broadcast-panel {
  --page-nav-height: 60px;
  --sheet-header-height: 58px;

  position: relative;

  &__loading {
    position: relative;
    grid-column-start: 1;
    grid-column-end: 3;
    height: 150px;
  }

  // panel as sheet
  &--sheet {
    grid-area: sheet;
    height: var(--sheet-header-height);
  }

  &--popup {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    display: none;
    height: 0;
  }

  // panel as sidebar
  &--sidebar {
    grid-area: sidebar;
    width: 42px;
    height: 100%;
    transition: width 300ms;
  }

  // player container|wrapper
  &__player {
    position: relative;
    height: 0;
    padding-top: calc((9 / 16) * 100%);
    overflow: hidden;
    background-color: #bdbdbd;
    border-radius: 5px;

    &--overflow-auto {
      overflow: auto;
    }

    &-container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &-container--empty::before {
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 18px;
      content: attr(data-text);
      transform: translate(-50%, -50%);
    }
  }

  /*
    Helps determine the actual size of the visible area,
    taking into account the top site menu.
  */
  &__shadow-border {
    position: fixed;
    top: var(--page-nav-height);
    right: 0;
    bottom: 0;
    opacity: 0;
  }

  &__overlay {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    height: 100vh;
    background-color: @modal-overlay-color;
  }

  &__popup {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 110;
    height: ~"calc(100vh - var(--page-nav-height))";
    background-color: @modal-form-background;
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr;
    max-width: 568px;
    min-height: 200px;
    max-height: 100%;
    margin: 0 auto;
    overflow: hidden;
    background-color: @white;

    // panel blocks
    &-header {
      grid-area: header;
    }

    &-player {
      grid-area: player;
    }

    &-speakers {
      grid-area: speakers;
      overflow: hidden;
      border-bottom: solid 1px #f2f2f2;
    }

    &-info {
      grid-area: info;
    }

    &-controls {
      grid-area: controls;
      overflow: hidden;
    }

    @media (min-width: 768px) {
      max-width: 768px;
    }

    @media (min-width: 1024px) {
      max-width: initial;
      margin: initial;
    }

    @media @touch-landscape and (max-width: 900px) and (max-height: 480px) {
      max-width: 640px;
    }
  }

  // sheet mode
  &--sheet &__container {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
    border-radius: 12px 12px 0 0;
    box-shadow: 0 -1px 7px rgba(58, 69, 91, 0.2);
    transition: transform 0.3s ease-out;
    transform: translate3d(0, 0, 0);
    will-change: transform;

    .container();

    // sheet indicator - "gray line"
    &-header::before {
      position: absolute;
      top: 12px;
      left: 50%;
      display: inline-block;
      width: 45px;
      height: 4px;
      content: "";
      background-color: @broadcast-control-light-color;
      border-radius: 11px;
      transform: translateX(-50%);
    }
  }

  &--sheet&--has-speakers &__container {
    grid-template-areas:
      "header"
      "player"
      "."
      "speakers"
      "controls";
    grid-template-rows: var(--sheet-header-height) auto 15px 1fr max-content;

    @media (orientation: landscape) {
      grid-template-areas:
        "header header"
        "player speakers"
        "player controls";
      grid-template-rows: var(--sheet-header-height) 1fr 1fr;
      grid-template-columns: 300px 1fr;
    }

    @media (orientation: landscape) and (min-width: 570px) {
      grid-template-columns: minmax(300px, 350px) minmax(320px, 1fr);
    }

    @media (orientation: landscape) and (min-width: 768px) {
      grid-template-columns: minmax(320px, 650px) minmax(320px, 1fr);
    }
  }

  &--sheet&--expanded &__container {
    transform: translate3d(0, calc(-100% + var(--sheet-header-height)), 0);
  }

  &--sheet&--full-height &__container {
    height: 100vh;
    border-radius: 0;
  }

  &--sheet &__container-player {
    padding: 0 15px;

    @media @touch-landscape {
      padding: 15px;
    }
  }

  &--popup &__container {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 2;
    grid-template-areas:
      "header header"
      "player controls";
    grid-template-columns: 60% 40%;
    width: 100%;
    max-width: 790px;
    min-height: 150px;
    padding: 15px 15px 15px 30px;
    border-radius: 8px;
    transform: translate(-50%, -50%);

    &-player {
      padding: 15px 0;
    }
  }

  &--popup&--expanded {
    display: block;
  }

  // sidebar mode
  &--sidebar &__container {
    grid-template-areas:
      "header"
      "player"
      "controls"
      "speakers"
      "info";
    grid-template-rows: var(--sheet-header-height) auto 1fr auto auto;
    width: 370px;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s;

    &-controls {
      overflow: auto;
    }
  }

  &--sidebar:not(&--onair) &__container-info {
    border-top: solid 1px @modal-block-border;
  }

  &--sidebar&--onair&--has-speakers &__container-info {
    border-bottom: solid 1px @modal-block-border;
  }

  &--sidebar&--onair &__container {
    grid-template-areas:
      "header"
      "player"
      "info"
      "speakers"
      "controls";
    grid-template-rows: var(--sheet-header-height) auto auto 1fr auto;
  }

  &--sidebar&--expanded {
    width: 370px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  }

  &--sidebar&--expanded &__container {
    opacity: 1;
  }

  &--sidebar &__player {
    border-radius: 0;
  }

  &__expander {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 15;
    width: 42px;
    height: 100%;
    background-color: @text-light-gray;
    border-radius: 0;
    transition: opacity 0.3s ease;
  }

  &--expanded &__expander {
    height: 58px;
    background-color: @white;
    border: 0;
    border-right: 1px solid @text-light-gray;
  }

  &--sidebar &__container-header {
    padding-left: 42px;
  }

  &--sidebar &__container-speakers {
    padding-top: 15px;
  }

  &--fullscreen&--popup &__overlay {
    position: fixed;
  }

  &--fullscreen-mobile&--popup &__container {
    --sheet-header-height: 30px;

    right: 10px;
    left: 10px;
    grid-template-columns: 1fr;
    width: auto;
    padding: 10px;

    .container();

    max-height: calc(100vh - 100px);
    transform: translate(0, -50%);

    @media (orientation: landscape) {
      max-height: calc(100vh - 30px);
    }
  }
}
</style>
